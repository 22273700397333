import axios from "axios";
import { reactive } from "vue";
const galleryStore = {
  state: reactive({
    data: null,
  }),
  setData() {
    axios
      .get(process.env.VUE_APP_API_PUBLICATION + process.env.VUE_APP_GALLERY_ID)
      .then(({ data }) => (this.state.data = data.data))
      .catch(() => {});
  },
};
galleryStore.setData();
export default galleryStore;
