<template>
  <div class="topline" v-if="infoline && infoline.is_active">
    <div
      class="topline__wrap"
      :class="{ '-single': infoline.list.length == 1 }"
    >
      <div
        v-for="(item, index) in infoline.list"
        class="topline__item align-items-center justify-content-center"
        :class="{ '-active': index === curActive }"
        @click="item.href === '' ? itemClick('banner', item.additional_field || item.text) : ''"
        :key="item.text"
      >
        <a v-if="item.href !== ''" :href="item.href" v-html="item.text" />
        <div v-else v-html="item.text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["mainStore"],
  data() {
    return {
      curActive: 0,
    };
  },
  methods: {
    itemClick(name, text) {
      if (name === "showitbanner") {
        this.$vfm.show({ component: "PopupIT" });
        return
      }
      this.$openPopupWithChat(text)
    },
    setupTimer(val) {
      if (val.list.length > 1) {
        setInterval(() => {
          if (this.curActive < val.list.length - 1) {
            this.curActive++;
          } else {
            this.curActive = 0;
          }
        }, 4000);
      }
    },
  },
  watch: {
    infoline(val) {
      this.setupTimer(val);
    },
  },
  mounted() {
    if (typeof this.infoline != "undefined") {
      this.setupTimer(this.infoline);
    }
  },
  computed: {
    infoline() {
      return this.mainStore.state.data?.info_line;
    },
  },
};
</script>
<style scoped>
.topline__wrap {
  width: 100%;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.05em;
  overflow: hidden;
  position: relative;
}
.topline__wrap a {
  color: inherit;
  text-decoration: none;
}
.topline__wrap::before {
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  background: radial-gradient(50% 28800% at 50% 50%, #509c3b 0%, #386d29 100%);
  background-size: 200vw 100%;
  background-repeat: repeat-x;
  width: 600vw;
  z-index: -1;
  animation-name: toplinegradient;
  animation-timing-function: linear;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.topline__item {
  position: relative;
  cursor: pointer;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 15px;
}
.topline__item.-active {
  display: flex;
  animation: rotate-animate 4s linear;
}
.topline__item strong {
  font-weight: 500;
}

.topline__wrap.-single .topline__item.-active {
  animation: rotate-animate-single 0.4s ease-out;
}

@keyframes toplinegradient {
  0% {
    left: 0;
  }
  to {
    left: -400vw;
  }
}

@keyframes rotate-animate {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(30px);
  }
}
@keyframes rotate-animate-single {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 575px) {
  .topline__wrap {
    font-size: 12px;
  }
}
</style>
