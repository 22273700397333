<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="vfm-gallery"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div class="vfm-gallery__container">
        <div class="h3 title" v-html="title" />
        <div class="list__wrap">
          <BuildingsPlan :src="src" />
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
import BuildingsPlan from "@/components/planFloor/BuildingsPlan.vue";
export default {
  components: {
    BuildingsPlan,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    src: {
      type: String,
      default: null
    }
  },
  methods: {
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped src="@css/popup.css"></style>
<style scoped>
.buildings {
  text-align: center;
  padding: 0px 15px 40px 15px;
}
.buildings :deep(svg) {
  width: 100%;
  height: auto;
  max-width: 600px;
}
</style>
