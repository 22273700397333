<template>
  <section class="home-block">
    <div class="content">
      <div class="container text-center">
        <div ref="title" class="h2 title text-center">Termoland для семьи и отдыха</div>
        <picture class="home__termoland_picture">
          <source :srcset="require('@i/termoland/termoland_mob.png')" media="(max-width: 767px)" />
          <img :src="require('@i/termoland/termoland.png')" loading="lazy" alt="" class="home__termoland_image" />
        </picture>
        <router-link :to="{ name: 'Termoland' }">
          <button class="btn btn-default btn-lime home__termoland_btn">
            Узнать подробнее
          </button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<style>
#termoland {
  padding-top: 0;
}

.home__termoland_btn {
  margin-top: 56px;
  height: 64px;
  background-color: #509C3B;
}

.home__termoland_image {
  margin-top: 35px;
  width: 100%;
}


@media (max-width: 768px) {
  .home__termoland_btn {
    margin-top: 32px;
    height: 48px;
  }

  .home__termoland_image {
    margin-top: 0;
  }
}
</style>