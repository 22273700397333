<template>
  <FlatSlider :items="items"
    ><template #title><div class="h2">Уникальные лоты</div></template>
    <template #item="item"
      ><FlipFlat :item="item" flip
        ><template #more>
          <router-link
            :to="{
              name: 'UnicLotFlat',
              params: {
                id: item.id,
              },
            }"
            >Подробнее</router-link
          ></template
        ></FlipFlat
      ></template
    >
    <template #bottom>
      <div class="btns-list d-md-flex justify-content-center">
        <div class="btns-list__item">
          <router-link
            :to="{ name: 'PlanUnicLotsSearch' }"
            class="btn btn-default"
          >
            Выбрать квартиру
          </router-link>
        </div>
        <div class="btns-list__item">
          <button
            class="btn btn-transparent"
            data-popup-with-chats
            data-popup-with-chats-title="Связаться со&nbsp;специалистом"
          >
            Связаться со&nbsp;специалистом
          </button>
        </div>
      </div>
    </template>
  </FlatSlider>
</template>

<script>
import items from "@/content/unicLots.js";
import FlatSlider from "@/components/home/FlatSlider.vue";
import FlipFlat from "@/components/FlipFlat.vue";

export default {
  components: { FlatSlider, FlipFlat },
  data() {
    return { items };
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .btns-list {
    text-align: center;
  }
}

@media (max-width: 374px) {
  .btns-list__item .btn-transparent {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
