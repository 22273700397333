<template>
  <input
    class="form__input"
    :class="status"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="validate"
  />
</template>

<script>
export default {
  props: {
    required: { type: Boolean, required: false },
    type: { type: String },
    modelValue: { type: String },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      status: "init",
    };
  },
  mounted() {
    if (!this.required) {
      this.status = "valid";
    }
  },
  methods: {
    validate() {
      if (this.required) {
        if (this.modelValue == "") {
          this.status = "error";
        } else {
          if (this.type == "email" && !this.validEmail(this.modelValue)) {
            this.status = "error";
          } else if (this.type == "tel" && this.modelValue.length < 18) {
            this.status = "error";
          } else {
            this.status = "valid";
          }
        }
      }
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style>
.form__input {
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0 15px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  background: #fff;
  color: inherit;
  box-shadow: none;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  font-family: inherit;
  transition: border-color 0.15s, color 0.15s;
}

.form__input::placeholder {
  color: #8c8f8c;
  opacity: 1;
}

.form__input:focus {
  border-color: #205640;
}

body.-notouch .form__input:hover:not(:focus) {
  border-color: #cecece;
}

.form__input.error:not(:focus) {
  border-color: red;
}
</style>
