<template>
  <div class="item text-white">
    <div v-if="item" class="item__image">
      <img
        v-if="item.preview.image_mobile || item.preview.src"
        :src="item.preview.image_mobile || item.preview.src"
        :alt="item.title"
        width="496"
        height="320"
        loading="lazy"
      />
      <div v-if="isVideo" class="item__play btn-play">
        <icon icon="play" />
      </div>
    </div>
    <div
      v-if="item"
      class="item__content d-flex justify-content-end flex-column"
    >
      <div class="item__title h4">
        <a
          href="javascript:void(0)"
          class="stretched-link cursor-zoom"
          @click="
            $vfm.show({
              component: 'PopupGallery',
              bind: {
                title: $filters.dateFormat(item.published_at),
                img: img,
                isInnerText: true,
                ratioPercent: true,
              },
            })
          "
          >{{ item.title }}</a
        >
      </div>
      <div class="item__time">
        {{ $filters.dateFormat(item.published_at) }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      img: [],
      isVideo: false,
    };
  },
  async mounted() {
    try {
      const { data } = await axios.get(
        this.$endpoint + "publication/" + this.item.id + "/?type=json"
      );
      const preview = this.item?.preview;
      if (!data) {
        throw new Error();
      }
      const blocks = data.data.content[0].body.blocks;
      if (blocks[0].type === "image") {
        this.img.push({ ...preview, src: preview.source });
      }
      if (blocks[0].type === "embed") {
        this.isVideo = true;
      }
      for (const block of blocks) {
        const data = block.data;
        if (!data) {
          continue;
        }
        switch (block.type) {
          case "image":
            this.img.push({
              src: data[0].source,
              image_desktop: data[0].image_desktop,
              image_tablet: data[0].image_tablet,
              image_mobile: data[0].image_mobile,
              text: data[0].caption,
            });
            break;
          case "embed":
            this.img.push({
              isVideo: true,
              src:
                data.embed +
                "?version=3&autoplay=1&controls=1&disablekb=1&fs=0&modestbranding=1&loop=1&rel=0",
            });
            break;
        }
      }
    } catch (err) {
      //
    } finally {
      //
    }
  },
};
</script>

<style scoped>
.item {
  position: relative;
  flex: 0 0 496px;
  max-width: 496px;
  margin-right: 24px;
  padding-bottom: 64.5%;
}

.swiper-slide .item {
  flex: 0 0 100%;
  max-width: 100%;
}

.item:last-child {
  margin-right: 0;
}

.item__image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(63 68 64 / 0) 49.93%, #3f4440 100%);
}

.item__play {
  position: absolute;
  top: 50%;
  left: 50%;
}

body.-notouch .item:hover .item__play {
  background: linear-gradient(180deg, #62a650, #a0c994);
}

body.-notouch .item:active .item__play {
  background: linear-gradient(180deg, #498c36, #86af7a);
}

.item__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.item__title {
  margin: 0 0 11px;
}

.item__title a {
  text-decoration: none;
}

.item__time {
  opacity: 0.8;
}
@media (max-width: 1279px) {
  .item__title {
    max-width: 410px;
    font-size: 33px;
    line-height: 1.1;
  }
}
@media (max-width: 991px) {
  .item__title {
    font-size: 30px;
  }

  .item__content {
    padding: 25px;
  }

  .item__time {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .item__title {
    margin-bottom: 5px;
    font-size: 27px;
  }

  .item__content {
    padding: 20px;
  }

  .item__time {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .item__play {
    margin-top: -70px;
  }

  .item__title {
    font-size: 24px;
  }

  .item__content {
    padding: 15px;
  }
}
</style>
