<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    name="it"
    @opened="opened"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div v-show="formOk" class="text-center">
        <div class="h4">Спасибо за&nbsp;заявку</div>
        <p>
          Ваша заявка успешно отправлена.<br />
          Мы&nbsp;свяжемся с&nbsp;Вами в&nbsp;ближайшее время.
        </p>
        <button class="btn btn-default btn-small w-100" @click="close">
          Закрыть
        </button>
      </div>
      <div v-show="!formOk" :class="{ sending: sending }">
        <div class="h4" v-html="title" />
        <p>
          Оставьте свои данные и&nbsp;наш менеджер свяжется с&nbsp;Вами
          в&nbsp;ближайшее время.
        </p>
        <form class="form" novalidate @submit.prevent="submit">
          <div class="form__row">
            <Input
              ref="name"
              v-model="form.name"
              type="text"
              required
              placeholder="ФИО заявителя *"
              aria-label="ФИО заявителя"
              name="name"
            />
          </div>
          <div class="form__row">
            <Input
              ref="phone"
              v-model="form.phone"
              v-maska="'+7 (###) ###-##-##'"
              type="tel"
              required
              placeholder="Ваш телефон *"
              aria-label="Ваш телефон"
              inputmode="tel"
              name="phone"
              @focus="if (form.phone == '') form.phone = '+7 (';"
              @blur="if (form.phone == '+7 (') form.phone = '';"
            />
          </div>
          <div class="form__row">
            <Input
              ref="inn"
              v-model="form.inn"
              required
              type="text"
              placeholder="ИНН организации *"
              aria-label="ИНН организации"
              name="inn"
              inputmode="numeric"
            />
          </div>
          <div class="form__row">
            <Agree ref="agree" v-model="form.agree" />
          </div>
          <div class="form__row">
            <button class="btn btn-default btn-small w-100" type="submit">
              {{ sending ? "Отправляется" : "Подать заявку" }}
            </button>
          </div>
          <div class="form__row mb-0 text-small">
            Выдачи начнутся после публикации Постановления
            Правительства&nbsp;РФ, но&nbsp;мы&nbsp;уже консультируем
            и&nbsp;принимаем&nbsp;заявки.
          </div>
        </form>
        <a href="javascript:void(0)" class="vfm__close" @click="close"
          ><Icon icon="close"
        /></a>
      </div>
      <div v-if="globalError" class="text-center mt-3" style="color: red">
        Не было отправлено!<br />
        Позвоните нам {{ phone }}
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import Input from "@/components/UI/Input.vue";
import Agree from "@/components/UI/Agree.vue";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import { is_touch } from "@/helpers.js";
import coMagicOfflineRequest from "@/coMagicOfflineRequest";
export default {
  components: {
    Input,
    Agree,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Подать заявку",
    },
  },
  inject: ["mainStore"],
  data() {
    return {
      form: {
        name: "",
        inn: "",
        phone: "",
        url: "",
        agree: true,
      },
      formOk: false,
      sending: false,
      globalError: false,
    };
  },
  computed: {
    phone() {
      return this.mainStore.state.data?.addresses.addr_list[0].addr_phone;
    },
  },
  watch: {
    $route(to) {
      this.form.url = to.fullPath;
    },
  },
  methods: {
    beforeOpen(event) {
      this.formOk = false;
      this.sending = false;
      this.globalError = false;
      _forEach(this.$refs, (ref) => {
        ref.status = "init";
      });
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
    opened() {
      if (!is_touch()) {
        this.$refs.name.$el.focus();
      }
    },
    submit() {
      if (!this.sending) {
        _forEach(this.$refs, (ref) => {
          ref.validate();
        });
        const errors = _filter(this.$refs, (ref) => {
          return ref.status == "error";
        });
        if (errors.length == 0) {
          this.sending = true;
          if (window.Comagic) {
            coMagicOfflineRequest(this.form, this.title, "")
              .then(() => {
                this.form.name = "";
                this.form.inn = "";
                this.form.phone = "";
                this.formOk = true;
                this.sending = false;
                setTimeout(() => {
                  this.$vfm.hideAll();
                }, 5000);
              })
              .catch(() => {
                this.globalError = true;
              })
              .finally(() => {});
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.text-small {
  text-align: center;
  font-size: 14px;
  line-height: 1.42;
  color: #8c8f8c;
}
</style>
