<template>
  <div class="person d-flex">
    <div v-if="person.img" class="person__image">
      <img
        loading="lazy"
        :src="person.img"
        width="60"
        height="60"
        :alt="person.name + ' — ' + person.position"
      />
    </div>
    <div class="person__content align-self-center">
      <div class="person__name">
        {{ person.name }}
      </div>
      <div class="person__position">
        {{ person.position }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.person__image {
  flex: 0 0 60px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
}

.person__name {
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 18px;
}

.person__name:last-child {
  margin-bottom: 0;
}

.person__position {
  color: #838682;
  font-size: 16px;
}
</style>
