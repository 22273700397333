<template>
  <div class="item">
    <div v-if="item && item.type == 'image'" class="item__image">
      <picture>
        <source
          v-if="item.image_tablet"
          :data-srcset="item.image_tablet"
          media="(min-width: 1921px)"
        />
        <source
          v-if="item.image_mobile"
          :data-srcset="item.image_mobile"
          media="(min-width: 768px)"
        />
        <img
          v-if="item.image_thumb || item.img"
          :data-src="item.image_thumb || item.img"
          class="cursor-zoom swiper-lazy"
          @click="$emit('showGallery', item.index)"
          loading="lazy"
        />
      </picture>
    </div>
    <div
      v-else-if="item && item.type == 'text'"
      class="item__content d-flex justify-content-between flex-column"
    >
      <blockquote class="item__text" v-html="item.text" />
      <Person v-if="item.person" class="item__person" :person="item.person" />
    </div>
  </div>
</template>

<script>
import Person from "@/components/UI/Person.vue";
export default {
  components: {
    Person,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  emits: ["showGallery"],
};
</script>

<style scoped>
.item {
  position: relative;
  width: 392px;
  height: 480px;
  margin-bottom: 24px;
  background: #eae8e4;
}

.item:last-child {
  margin-bottom: 0;
}

.item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__content {
  position: relative;
  min-height: 480px;
  padding: 30px;
}

.item__text {
  margin: 0 0 30px;
}

.item__text:last-child {
  margin-bottom: 0;
}
</style>
