<template>
  <Head>
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/DPl7ZjfCfILjU30qAkD1uzz7eN60iQIYgDLKJ1NO.jpg"
    />
    <meta property="og:url" :content="url" />
    <meta property="og:type" content="website" />
  </Head>
  <Header />

  <router-view v-slot="{ Component }">
    <component :is="Component" :key="$route.path" />
  </router-view>

  <Footer />
  <modals-container />
  <vue-progress-bar />
  <PopupPromo />
</template>

<script>
import { is_touch } from "@/helpers.js";
import mainStore from "@/store/main.js";
import galleryStore from "@/store/gallery.js";
import filterStore from "@/store/filter.js";
import favouritesStore from "@/store/favourites.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Head } from "@vueuse/head";
import PopupWithChats from "@osnova-adv/popup-with-chats";
// import PopupPromo from "@/components/PromoHolidays.vue";

export default {
  components: {
    Head,
    Header,
    Footer,
    // PopupPromo,
  },
  inject: ["mq"],
  provide: {
    mainStore,
    galleryStore,
    filterStore,
    favouritesStore,
  },
  computed: {
    url() {
      return window.location.origin + "" + this.$route.path;
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      if (
        ["PlanArchive", "PlanFloor", "PlanBuilding"].includes(to.name) &&
        this.mq.mdMinus
      ) {
        next({ name: "PlanSearch" });
      } else {
        if (to.path != from.path) {
          this.$Progress.start();
        }
        next();
      }
    });
    this.$router.afterEach((to, from) => {
      if (to.meta.customProgress == undefined) {
        if (to.path != from.path) {
          this.$Progress.finish();
        }
      }
    });
  },
  mounted() {
    window.addEventListener("load", () => {
      PopupWithChats.make("body", {
        callbackModalId: "popup-with-chats",
        successModalId: "success-popup-with-chats",
        agreementLink: "https://gk-osnova.ru/legal/privacy",
        title: process.env.VUE_APP_POPUP_DEFAULT_TITLE,
        subtitle: process.env.VUE_APP_POPUP_SUBTITLE,
        messagerDescription: process.env.VUE_APP_POPUP_MESSAGER_DESC,
      });
    });
    if (is_touch()) {
      document.body.classList.add("-istouch");
    } else {
      document.body.classList.add("-notouch");
    }
    this.$Progress.finish();
    // setTimeout(() => {
    //   this.$vfm.show("promoNY");
    // }, 1000);
  },
};
</script>
<style>
@import "~@css/bootstrap.css";
@import "~@css/style.css";
@import "~@osnova-adv/popup-with-chats/dist/popup-with-chats.css";

.popup-with-chats .popup-with-chats__title {
  color: #205640;
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  font-family: "Oranienbaum", "Times New Roman", sans-serif;
  line-height: 96%;
  letter-spacing: 0.05em;
  text-transform: unset;
}

.popup-with-chats .popup-with-chats__subtitle {
  color: #666;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  line-height: 132.5%;
  opacity: 0.7;
}

.popup-with-chats .popup-with-chats__input {
  color: #142654;
  font-weight: 400;
  font-size: 14px;
}

.popup-with-chats .popup-with-chats__agreement-link {
  border-color: #205640;
}

.popup-with-chats .popup-with-chats__button {
  width: 100%;
  margin: 0;
  border-color: #205640;
  border-radius: 32px;
  background: #205640;
}

.popup-with-chats .popup-with-chats__soc-button {
  border-color: #205640;
}

.popup-with-chats .popup-with-chats__soc-button .popup-with-chats__icon path {
  fill: #205640;
}

.popup-with-chats .popup-with-chats__field-input {
  height: auto;
  padding: 12px 0;
  border: 0;
  border-bottom: 1px solid #cbd0dd;
  border-radius: 0;
}

.popup-with-chats .popup-with-chats__label {
  transition: none;
}

.popup-with-chats
  .popup-with-chats__field-input--with-content
  .popup-with-chats__label {
  display: none;
}

@media screen and (min-width: 768px) {
  .popup-with-chats .content-popup-with-chats__title {
    font-size: 32px;
  }

  .popup-with-chats .content-popup-with-chats__subtitle {
    font-size: 15px;
    line-height: 18px;
  }

  .popup-with-chats
    .content-popup-with-chats
    .form-content-popup-with-chats__button {
    width: calc(100% + 44px);
    margin-left: -22px;
  }
}
</style>
