import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      headerClass: "-white",
      hasTopline: true,
    },
  },
  // {
  //   path: "/home2/",
  //   name: "Home2",
  //   component: () => import(/* webpackPrefetch: true */ "@/pages/Home2.vue"),
  //   meta: {
  //     headerClass: "-white",
  //   },
  // },
  {
    path: "/apartments/",
    name: "PlanArchive",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/PlanArchive.vue"),
    meta: {
      headerClass: "-white",
      customProgress: true,
    },
  },
  {
    path: "/unic-lots/",
    name: "PlanUnicLotsSearch",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/UnicLots.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/korp:korp(\\d+)/",
    name: "PlanBuilding",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/PlanBuilding.vue"),
    meta: {
      headerClass: "-white",
      customProgress: true,
    },
    redirect: { name: "PlanArchive" },
  },
  {
    path: "/apartments/korp:korp(\\d+)/floor:floor(\\d+)/",
    name: "PlanFloor",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/PlanFloor.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/unic-lots/id:id/",
    name: "UnicLotFlat",
    component: () => import(/* webpackPrefetch: true */ "@/pages/PlanFlat.vue"),
    meta: {
      isCommerce: true,
      isUnic: true,
      customProgress: true,
    },
  },
  {
    path: "/apartments/korp:korp(\\d+)/sec:sec(\\d+)/floor:floor(\\d+)/fnumb:fnumb(\\d+)/numb:numb(\\d+)/quantity:quantity/id:id/",
    name: "PlanFlat",
    component: () => import(/* webpackPrefetch: true */ "@/pages/PlanFlat.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/korp:korp(\\d+)/sec:sec(\\d+)/floor:floor(\\d+)/fnumb:fnumb(\\d+)/numb:numb(\\d+)/quantity:quantity/id:id/",
    name: "SearchPlanFlat",
    component: () => import(/* webpackPrefetch: true */ "@/pages/PlanFlat.vue"),
    meta: {
      isSearch: true,
      customProgress: true,
    },
  },
  {
    path: "/apartments/search/",
    name: "PlanSearch",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/PlanSearch.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/studii/",
    name: "Flat0",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Flat0.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/odnokomnatnye/",
    name: "Flat1",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Flat1.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/dvuhkomnatnye/",
    name: "Flat2",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Flat2.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/trehkomnatnye/",
    name: "Flat3",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Flat3.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/chetyrekhkomnatnye/",
    name: "Flat4",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Flat4.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/apartments/pyatikomnatnye/",
    name: "Flat5",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Flat5.vue"),
    meta: {
      customProgress: true,
    },
  },

  {
    path: "/apartments/favourites/",
    name: "PlanFavourites",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/PlanFavourites.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/news/",
    name: "News",
    component: () => import(/* webpackPrefetch: true */ "@/pages/News.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/news/:slug/",
    name: "NewsInner",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/NewsInner.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/policy/",
    name: "Policy",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Policy.vue"),
  },
  {
    path: "/contacts/",
    name: "Contacts",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Contacts.vue"),
  },
  {
    path: "/documents/",
    name: "Documents",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/Documents.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/developer/",
    name: "Developer",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/Developer.vue"),
  },
  {
    path: "/gallery/",
    name: "Gallery",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Gallery.vue"),
  },
  {
    path: "/construction/",
    name: "Construction",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/Construction.vue"),
    meta: {
      customProgress: true,
    },
  },
  {
    path: "/entertainment/",
    name: "Entertainment",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/Entertainment.vue"),
    meta: {
      headerClass: "-white",
    },
  },
  {
    path: "/kids/",
    name: "Kids",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Kids.vue"),
    meta: {
      headerClass: "-white",
    },
  },
  {
    path: "/sport/",
    name: "Sport",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Sport.vue"),
    meta: {
      headerClass: "-white",
    },
  },
  {
    path: "/termoland/",
    name: "Termoland",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Termoland.vue"),
    meta: {
      headerClass: "-white",
    },
  },
  {
    path: "/advantages/",
    name: "Advantages",
    component: () =>
      import(/* webpackPrefetch: true */ "@/pages/Advantages.vue"),
  },
  {
    path: "/purchase/",
    name: "Purchase",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Purchase.vue"),
  },
  {
    path: "/smart/",
    name: "Smart",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Smart.vue"),
    meta: {
      headerClass: "-white",
    },
  },
  {
    path: "/parking/",
    name: "Parking",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Parking.vue"),
    meta: {
      headerClass: "-white",
    },
  },
  {
    path: "/buyonline/",
    name: "Online",
    component: () => import(/* webpackPrefetch: true */ "@/pages/Online.vue"),
  },
  {
    path: "/forms/form-booklet.php",
  },
  {
    path: "/forms/form-callback.php",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import(/* webpackPrefetch: true */ "@/pages/NotFound.vue"),
  },
  {
    path: "/trade-in-special/",
    name: "TradeInSpecial",
    component: () => import("@/pages/TradeInSpecial.vue"),
  },
  {
    path: "/trade-in-special-test/",
    name: "TradeInSpecialTest",
    component: () => import("@/pages/TradeInSpecialTest.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        let delta = 0;
        switch (to.hash) {
          case "#gallery":
            delta = 25;
            break;
          case "#building":
            delta = -50;
            break;
          case "#progulka":
            delta = 50;
            break;
        }
        const position = {
          behavior: "smooth",
          el: to.hash,
          top: 75 + delta,
        };
        if (document.querySelector(to.hash)) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(position);
            }, 500);
          });
        } else {
          return { top: 0 };
        }
      } else {
        return { top: 0 };
      }
    }
  },
});

export default router;
