<template>
  <div class="countdown">
    <div class="countdown__box" v-html="title" />
    <div class="countdown__table">
      <div
        :key="intervalCount"
        :class="[
          'countdown__cards',
          { 'countdown__cards--flip': animationActive },
        ]"
      >
        <div class="countdown__card">
          <div class="countdown__card-top">{{ intervalCount }}</div>
          <div class="countdown__card-bottom" :data-i="intervalCount + 1" />
          <div class="countdown__card-back" :data-i="intervalCount + 1">
            <div class="countdown__card-bottom" :data-i="intervalCount" />
          </div>
        </div>
      </div>
      <div class="countdown__line-bg" />
      <div class="countdown__line" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    fromCount: {
      type: Number,
      default: 0,
    },
    toCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      difference: 0,
      interval: null,
      intervalCount: 0,
      animationActive: false,
      animationInited: false,
      animationDelay: 750,
      animationInterval: 300,
    };
  },
  mounted() {
    this.initCount();
    this.startAnimation();
  },
  activated() {
    this.startAnimation();
  },
  deactivated() {
    this.animationInited = false;
  },
  methods: {
    initCount() {
      this.difference = this.fromCount - this.toCount;
    },
    startAnimation() {
      if (this.difference <= 0 || this.animationInited) {
        return (this.intervalCount = this.toCount);
      }
      this.intervalCount = this.fromCount;
      setTimeout(() => {
        this.interval = setInterval(() => {
          if (this.intervalCount === this.toCount) {
            this.$emit("animationEnd", this.toCount);
            return clearInterval(this.interval);
          }
          this.animationActive = true;
          this.intervalCount -= 1;
        }, this.animationInterval);
      }, this.animationDelay);
      this.animationInited = true;
    },
  },
};
</script>

<style scoped>
.countdown {
  --bgc: rgba(32, 86, 64, 0.5);
  --card-h: 34px;
  --line-h: 2px;
  position: relative;
  width: 98px;
  height: 90px;
  color: rgba(63, 68, 64, 1);
  line-height: 1.15;
}

.countdown__table {
  z-index: 1;
  position: relative;
  width: 84px;
  margin: 0 auto;
}

.countdown__card {
  width: 100%;
  height: var(--card-h);
  border-radius: 4px;
}

.countdown__card:not(:last-child) {
  margin-bottom: var(--line-h);
}

.countdown__date {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 80px;
  font-weight: 400;
  text-align: center;
}

.countdown__line-bg,
.countdown__line {
  z-index: 1;
  position: absolute;
  top: var(--card-h);
  width: 100%;
  height: var(--line-h);
  --p: 6px;
}

.countdown__line-bg::before,
.countdown__line::before,
.countdown__line-bg::after,
.countdown__line::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 12px;
  border-radius: 10px;
  background-color: inherit;
}

.countdown__line-bg::before,
.countdown__line::before {
  left: var(--p);
}

.countdown__line-bg::after,
.countdown__line::after {
  right: var(--p);
}

.countdown__line-bg {
  background-color: #fff;
}

.countdown__line {
  background-color: var(--bgc);
}

.countdown__box {
  position: absolute;
  top: 27px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 0 4px 4px;
  background-color: var(--bgc);
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.countdown__table {
  width: 84px;
  height: 70px;
  perspective: 400px;
  text-align: center;
  margin: 0 auto;
}

.countdown__table *,
.countdown__table *:before,
.countdown__table *:after {
  box-sizing: border-box;
}

.countdown__cards {
  z-index: 1;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.countdown__cards--flip .countdown__card-back::before {
  transform-origin: center bottom;
  animation: flip-top 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35) both;
}

.countdown__cards--flip .countdown__card-back .countdown__card-bottom {
  transform-origin: center top;
  animation: flip-bottom 0.4s cubic-bezier(0.15, 0.45, 0.28, 1) both;
}

.countdown__card {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 34px;
  font-weight: 600;
  font-size: 56px;
}

.countdown__card-top,
.countdown__card-bottom,
.countdown__card-back::before,
.countdown__card-back::after {
  transform: translateZ(0);
  transform-style: preserve-3d;
  display: block;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border-top: 1px solid #bdbdbd;
  padding-top: 0;
  background-color: #e0e0e0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.countdown__card-bottom {
  position: absolute;
  top: 50%;
  left: 0;
  border-top: none;
  background-color: #fff;
  overflow: hidden;
}

.countdown__card-bottom::after {
  display: block;
  margin-top: -34px;
}

.countdown__card-back::before,
.countdown__card-bottom::after {
  content: attr(data-i);
}

.countdown__card-back {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
}

.countdown__card-back::before {
  z-index: -1;
  position: relative;
  overflow: hidden;
}

@keyframes flip-top {
  0% {
    z-index: 2;
    transform: rotateX(0deg);
  }

  0%,
  99% {
    opacity: 1;
  }

  100% {
    transform: rotateX(-180deg);
    opacity: 0;
  }
}

@keyframes flip-bottom {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(180deg);
    opacity: 0;
  }

  51% {
    opacity: 0.99;
  }

  100% {
    z-index: 5;
    transform: rotateX(0deg);
    opacity: 0.99;
  }
}
</style>
