<template>
  <div class="form__agree">
    <label>
      <input
        class="visually-hidden"
        type="checkbox"
        :class="status"
        :checked="modelValue"
        @change="
          $emit('update:modelValue', $event.target.checked);
          validate($event.target.checked);
        "
      />
      <span />
      Я&nbsp;согласен(-на) с&nbsp;условиями обработки
      <router-link :to="{ name: 'Policy' }" target="_blank"
        >персональных данных</router-link
      >
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      status: "init",
    };
  },
  methods: {
    validate(e) {
      if (typeof e == "undefined") {
        e = this.modelValue;
      }
      if (e) {
        this.status = "valid";
      } else {
        this.status = "error";
      }
    },
  },
};
</script>

<style scoped>
.form__agree {
  position: relative;
  padding-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42;
}

.form__agree label {
  display: block;
  padding-left: 30px;
  cursor: pointer;
}

.form__agree input {
  position: absolute;
  top: 0;
  left: 0;
}

.form__agree input.visually-hidden[type="checkbox"] + span {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
