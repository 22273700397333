<template>
  <div class="breadcrumbs d-inline-flex flex-wrap">
    <div
      v-for="(link, index) in links"
      :key="link.text"
      class="breadcrumbs__item"
    >
      <router-link v-if="link.name" :to="link">{{
        stripTags(link.text)
      }}</router-link
      ><span v-else class="breadcrumbs__current">{{
        stripTags(link.text)
      }}</span
      ><span v-if="index + 1 < links.length" class="breadcrumbs__split">/</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    stripTags(str) {
      if (str !== undefined) {
        return str.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, " ");
      }
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  margin-bottom: 17px;
  font-size: 18px;
}

.breadcrumbs__split {
  margin: 0 8px;
}

.breadcrumbs__current {
  color: #8c8f8c;
}

.breadcrumbs__item a {
  color: inherit;
}

body.-notouch .breadcrumbs__item a:hover {
  color: #205640;
}
@media (max-width: 767px) {
  .breadcrumbs {
    margin-bottom: 13px;
  }
}

.text-white .breadcrumbs__current {
  color: #fff;
  opacity: 0.6;
}
body.-notouch .text-white .breadcrumbs__item a:hover {
  color: #fff;
}
</style>
