<template>
  <FlatSlider class="second-flats" :items="items" particle>
    <template #title
      ><div class="h2">
        Планировки <br />
        <small>2-й очереди</small>
      </div></template
    >
    <template #item="item"
      ><FlipFlat
        :item="item"
        data-popup-with-chats
        data-popup-with-chats-title="Узнать подробнее"
    /></template>
    <template #bottom>
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-default"
          data-popup-with-chats
          data-popup-with-chats-title="Узнать подробнее"
        >
          Узнать подробнее
        </button>
      </div>
    </template>
  </FlatSlider>
</template>

<script>
import items from "@/content/secondFlats.js";
import FlatSlider from "@/components/home/FlatSlider.vue";
import FlipFlat from "@/components/FlipFlat.vue";

export default {
  components: { FlatSlider, FlipFlat },
  data() {
    return { items };
  },
};
</script>

<style scoped>
.second-flats .flip-flat {
  cursor: pointer;
}
</style>
