<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    :class="['vfm-gallery', { 'inner-text': isInnerText }]"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div
        class="vfm-gallery__container"
        :class="{ single: img.length == 1, ratioPercent: ratioPercent }"
      >
        <div class="h3 title" v-html="title" />
        <div class="list__wrap">
          <swiper
            v-if="img.length > 1"
            class="list"
            :class="{ 'cursor-drag': img.length > 1 }"
            :initial-slide="index"
            navigation
            keyboard
            :lazy="{ loadPrevNext: true, loadPrevNextAmount: 2 }"
            :slides-per-view="1"
            :space-between="0"
            @swiper="onSwiper"
            @slideChange="slideChange"
          >
            <swiper-slide
              v-for="(item, i) in img"
              :key="i"
              :class="['item', { video: item.isVideo }]"
            >
              <div class="item__image">
                <div v-if="item.isVideo" class="vfm-video__container">
                  <iframe allow="autoplay" :src="item.src" />
                </div>
                <picture v-else>
                  <source
                    v-if="item.src"
                    :srcset="item.src"
                    media="(min-width: 1921px)"
                  />
                  <source
                    v-if="item.image_desktop"
                    :srcset="item.image_desktop"
                    media="(min-width: 1440px)"
                  />
                  <source
                    v-if="item.image_tablet"
                    :srcset="item.image_tablet"
                    media="(min-width: 768px)"
                  />
                  <img
                    v-if="item.image_mobile || item.src"
                    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    :data-src="item.image_mobile || item.src"
                    :alt="item.text || title"
                    width="1200"
                    height="800"
                    class="image swiper-lazy"
                  />
                </picture>
              </div>
              <div class="item__text" v-html="item.text || item.description" />
            </swiper-slide>
          </swiper>
          <div
            v-for="(item, i) in img"
            v-else
            :key="i"
            :class="['item', { video: item.isVideo }]"
          >
            <div class="item__image">
              <div v-if="item.isVideo" class="vfm-video__container">
                <iframe allow="autoplay" :src="item.src" />
              </div>
              <picture v-else>
                <source
                  v-if="item.src"
                  :srcset="item.src"
                  media="(min-width: 1921px)"
                />
                <source
                  v-if="item.image_desktop"
                  :srcset="item.image_desktop"
                  media="(min-width: 1440px)"
                />
                <source
                  v-if="item.image_tablet"
                  :srcset="item.image_tablet"
                  media="(min-width: 768px)"
                />
                <img
                  v-if="item.image_mobile || item.src"
                  :src="item.image_mobile || item.src"
                  :alt="item.text || title"
                  width="1200"
                  height="800"
                />
              </picture>
            </div>
            <div class="item__text" v-html="item.text" />
          </div>
        </div>
        <div v-if="img.length > 1" class="count h3 d-flex">
          <div class="count__current">
            {{ current }}
          </div>
          <div class="count__all">
            /<span>{{ img.length }}</span>
          </div>
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
import SwiperCore, { Navigation, Lazy, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Lazy, Keyboard]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    img: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    isInnerText: {
      type: Boolean,
      default: false,
    },
    ratioPercent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiper: null,
      current: 1,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.$nextTick(() => {
        swiper.update();
      });
    },
    slideChange(swiper) {
      this.current = swiper.activeIndex + 1;
    },
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped src="@css/popup.css" />
