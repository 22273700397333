<template>
  <div class="item">
    <div v-if="item.type == 'text'" class="item-text">
      <Icon icon="quote" />
      <blockquote class="item-text__text" v-html="item.text" />
      <Person
        v-if="item.person"
        class="item-text__person"
        :person="item.person"
      />
    </div>
    <div v-else class="item-image">
      <img
        :src="item.src"
        loading="lazy"
        width="392"
        height="480"
        alt=""
        @click="$emit('showGallery', item.index)"
      />
    </div>
  </div>
</template>

<script>
import Person from "@/components/UI/Person.vue";
export default {
  components: {
    Person,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ["showGallery"],
};
</script>

<style scoped>
.item-image {
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 122%;
}

.item-image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item-text {
  position: relative;
  margin-top: 50px;
  margin-bottom: 40px;
  padding-top: 60px;
}

.item-text .icon-quote {
  position: absolute;
  top: -16px;
  left: 0;
  z-index: -1;
}

.item-text__text {
  margin: 0 0 25px;
}

.item-text__text:last-child {
  margin-bottom: 0;
}
</style>
