<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="vfm-gallery"
    :lock-scroll="lockScroll"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div class="vfm-gallery__container">
        <div class="h3 title" v-html="title"></div>
        <div class="list__wrap">
          <swiper
            v-if="list"
            class="list"
            navigation
            keyboard
            :slides-per-view="1"
            :space-between="0"
            @swiper="onSwiper"
            @slideChange="slideChange"
          >
            <swiper-slide
              v-for="(item, i) in list"
              :key="i"
              class="item"
            >
              <div v-if="isLive" class="vfm-video__container">
                <div v-if="item.active_stub" class="vfm-video__error">
                  <img src="@i/failed_camera.svg" />
                  <div class="vfm-video__error-text">
                    Онлайн-трансляция хода строительства в&nbsp;данный момент
                    приостановлена. Наша бригада принимает все меры для
                    оперативного устранения неполадок.
                  </div>
                </div>
                <iframe
                  v-else
                  width="560"
                  height="315"
                  :src="item.url"
                  frameborder="0"
                  allowfullscreen
                />
              </div>
              <video
                v-else 
                class="vfm-video__src"
                :src="item"
                playsinline
                ref="video"
                muted
              ></video>
              <div class="item__text">
                {{ item.name }}
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="count h3 d-flex">
          <div class="count__current">
            {{ current }}
          </div>
          <div class="count__all">
            /<span>{{ list?.length }}</span>
          </div>
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
import SwiperCore, { Navigation, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Keyboard]);
import { is_touch } from "@/helpers.js";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  inject: ["mainStore"],
  data() {
    return {
      swiper: null,
      current: 1,
      lockScroll: !is_touch(),
    };
  },
  props: {
    title: {
      type: String,
      default: 'Онлайн-камера'
    },
    videos: {
      type: Array,
      default () {
        return null
      }
    }
  },
  computed: {
    live() {
      return this.mainStore.state.data?.building_broadcast;
    },
    list () {
      if (this.videos) {
        return this.videos
      }
      return this.live?.list
    },
    isLive () {
      if (this.videos) {
        return false
      }
      return true
    }
  },
  mounted () {
    if (!this.isLive) {
      this.runVideo(0)
    }
  },
  methods: {
    onSwiper(swiper) {
      this.$nextTick(() => {
        swiper.update();
      });
    },
    slideChange(swiper) {
      this.current = swiper.activeIndex + 1;
      this.runVideo()
    },
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
    runVideo () {
      for (let j = 0; j < this.$refs.video.length; j++) {
        this.$refs.video[j].pause()
        this.$refs.video[j].currentTime = 0;
      }
      setTimeout(() => {
        this.$refs.video[this.current - 1].play()
      }, 500)
    }
  },
};
</script>

<style scoped src="@css/popup.css"></style>
<style scoped>
.vfm-video__error {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #509c3b;
  border-bottom: 1px solid #509c3b;
  box-sizing: border-box;
  padding: 15px;
}
.vfm-video__error img {
  width: 90px;
}
.vfm-video__error-text {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #3f4440;
  width: 522px;
  max-width: 100%;
}
.vfm-video__src {
  width: 100%;
}
@media screen and (min-width: 375px) {
  .vfm-video__error-text {
    font-size: 13px;
    margin-top: 30px;
  }
  .vfm-video__error img {
    width: 100px;
  }
  .vfm-video__error {
    padding: 24px;
  }
}
@media screen and (min-width: 576px) {
  .vfm-video__error {
    border: 1px solid #509c3b;
  }
  .vfm-video__error img {
    width: auto;
  }
  .vfm-video__error-text {
    font-size: 20px;
  }
}
</style>
