<template>
  <section class="home-block">
    <div class="content">
      <div class="container">
        <div ref="title" class="h2 title">Рядом история и&nbsp;красота</div>
        <SlidesScroll v-if="mq.lgPlus">
          <FlipItem v-for="item in items" :key="item.title" :item="item" />
        </SlidesScroll>
        <div v-else class="list">
          <swiper
            class="hasPagination"
            navigation
            :pagination="{ clickable: true }"
            :preload-images="false"
            :slides-per-view="'auto'"
            :space-between="24"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="item in items" :key="item.title">
              <GalleryItem :item="item" :no-popup-mobile="true" />
            </swiper-slide>
          </swiper>
        </div>
        <div ref="bottom" class="btns-list d-md-flex justify-content-center">
          <div class="btns-list__item">
            <router-link :to="{ name: 'PlanArchive' }" class="btn btn-default">
              Выбрать квартиру
            </router-link>
          </div>
          <div class="btns-list__item">
            <button class="btn btn-transparent" data-popup-with-chats data-popup-with-chats-title="Связаться со&nbsp;специалистом">
              Связаться со&nbsp;специалистом
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesScroll from "@/components/SlidesScroll.vue";
import FlipItem from "@/components/FlipItem.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    SlidesScroll,
    FlipItem,
    GalleryItem,
    Swiper,
    SwiperSlide,
  },
  inject: ["mq"],
  data() {
    return {
      items: [
        {
          img: require("@i/html/home/near-1.jpg"),
          title: "Останкинская телебашня",
          time: "5&nbsp;минут на&nbsp;машине",
          text: "Самое высокое здание в&nbsp;России и&nbsp;Европе. Главная смотровая площадка города с&nbsp;уникальным рестораном, где во&nbsp;время трапезы можно совершить полный оборот на&nbsp;360&rsquo; в&nbsp;и&nbsp;трижды увидеть Москву со&nbsp;всех точек обзора.",
        },
        {
          img: require("@i/html/home/near-2.jpg"),
          title: "Музей космонавтики",
          time: "5&nbsp;минут на&nbsp;машине",
          text: "Знаменитая титановая ракета&nbsp;&mdash; символ технологического скачка середины 20&nbsp;века. Здесь располагается один из&nbsp;крупнейших научно-исторических музеев мира с&nbsp;образцами ракетно-космической техники, вещественными реликвиями, документами.",
        },
        {
          img: require("@i/html/home/near-5.jpg"),
          title: "Музей кино",
          time: "На&nbsp;ВДНХ",
          text: "Уникальное кинематографическое пространство с&nbsp;несколькими кинозалами, где проходят монументальные ретроспективы и&nbsp;фестивали авторских фильмов. История музея начинается с&nbsp;1920&nbsp;годов, когда только появилась идея сохранять произведения кино для будущих поколений.",
        },
        {
          img: require("@i/html/home/near-3.jpg"),
          title: "ТЦ Капитолий Марьина Роща",
          time: "15&nbsp;минут на&nbsp;машине",
          text: "Современный торговый центр для всей семьи с&nbsp;большим выбором международных и&nbsp;локальных брендов, гипермаркетом АШАН-сити и&nbsp;кинотеатром сети КАРО.",
        },
        {
          img: require("@i/html/home/near-4.jpg"),
          title: "Рижский рынок",
          time: "20&nbsp;минут на&nbsp;машине",
          text: "Свежие фермерские продукты, парное мясо, овощи и&nbsp;сухофрукты&nbsp;&mdash; сюда приезжают за&nbsp;этим. Рижский&nbsp;&mdash; это и&nbsp;главный цветочный рынок столицы. Цветов здесь огромный выбор и&nbsp;они всегда свежие!",
        },
      ],
    };
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "10% center",
            scrub: 1.5,
          },
        })
        .from(this.$refs.title, {
          y: 150,
        });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.bottom,
            toggleActions: "play reverse play reverse",
          },
        })
        .from(this.$refs.bottom, {
          y: 50,
          autoAlpha: 0,
        });
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>

<style scoped>
.content {
  position: relative;
}

.title {
  margin-bottom: 80px;
  text-align: center;
}

.list .swiper-container {
  overflow: inherit;
}

.list :deep(.swiper-slide) {
  width: 392px;
}

.btns-list {
  margin-top: 80px;
}
@media (max-width: 1279px) {
  .title {
    margin-bottom: 65px;
  }
}
@media (max-width: 991px) {
  .title {
    margin-bottom: 40px;
  }

  .list :deep(.item__image) {
    height: 450px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 187px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 32px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 203px;
  }

  .btns-list {
    margin-top: 110px;
    text-align: center;
  }

  .btns-list__item {
    margin-bottom: 10px;
  }

  .btns-list__item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }

  .list :deep(.item__text) {
    font-size: 14px;
    line-height: 1.4;
  }

  .list :deep(.item__image) {
    height: 400px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 178px;
  }
}
@media (max-width: 374px) {
  .list :deep(.swiper-slide) {
    width: 290px;
  }

  .btns-list__item .btn-transparent {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
