<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    name="parentMortgage"
    class="popup-promo-video"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="popup-promo-video__body" :class="additionClass">
      <div class="popup-promo-video__background">
        <picture v-if="img">
          <source
            v-for="(item, i) in img?.sources"
            :key="i"
            :srcset="item.src"
            :media="item.media"
            type="image/webp"
          />
          <img :src="img.default" loading="lazy" class="popup-base__image" />
        </picture>
        <video 
          :poster="video.poster"
          autoplay="autoplay"
          loop="loop"
          muted
          playsinline
          class="popup-base__video"
          ref="video"
          v-if="video"
        >
        <source v-for="(item, i) in video.sources" :key="i" :type="item.type" :src="item.src" :media="item.media">
      </video>
      </div>
      <div class="popup-promo-video__content">
        <div class="popup-promo-video__title h4" v-html="title"/>
        <div class="popup-promo-video__desc" v-html="desc"/>
        <div class="popup-promo-video__button">
          <button
            class="btn btn-default btn-small"
            data-popup-with-chats
            data-popup-with-chats-title="Узнать подробнее"
            data-popup-with-chats-source-id="promo-banner"
            @click="close"
          >
            Узнать подробнее
          </button>
        </div>
        <div class="popup-promo-video__bottom" v-if="additionText">
          <div class="popup-promo-video__additionText" v-html="additionText" />
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><img src="@/assets/i/cross.svg"></a>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  inject: ["mainStore"],
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    additionText: {
      type: String,
      default: ''
    },
    additionClass: {
      type: String,
      default: ''
    },
    img: {
      default: null
    },
    video: {
      default: null
    }
  },
  mounted () {
    if (this.$refs.video) {
      this.$refs.video.play();
    }
  },
  methods: {
    beforeOpen(event) {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
      this.mainStore.state.timerAnimation = true
    },
  },
};
</script>
<style scoped>
  ::v-deep .green {
    color: #509C3B;
  }

.popup-promo-video__body {
  background-color: #fff;
  width: 320px;
  /* width: 584px; */
  max-width: calc(100vw - 30px);
  margin: 0 15px;
  position: relative;
}

.popup-promo-video__background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.popup-promo-video ::v-deep .vfm__content::before, 
.popup-promo-video ::v-deep .vfm__content::after {
  display: none;
}

.popup-promo-video__content {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  min-height: 312px;
}
.popup-promo-video__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.popup-promo-video__title {
  margin-bottom: 16px;
  line-height: 40px;
  font-size: 33px;
  /* font-size: 36px; */
}
.popup-promo-video ::v-deep span {
  display: inline-block;
}
.popup-promo-video__desc {
  font-size: 18px;
  margin-bottom: 24px;
}


.popup-promo-video__additionText {
  color: #333;
  font-family: var(--font-family-2);
  font-size: 12px;
  line-height: 120%;
  margin-top: 10px;
}
.popup-promo-video__button {
  margin-top: auto;
  width: 390px;
  max-width: 100%;
}
.popup-promo-video__button .btn {
  width: 100%;
  height: 58px;
}
.popup-promo-video__background--no-mob {
  display: none;
}

@media screen and (max-width: 500px) {
  .popup-promo-video__title {
    line-height: 1.1;
  }
}
@media screen and (min-width: 768px) {
  .popup-promo-video__content {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 991px) {
  .popup-promo-video__title {
    /* width: 300px; */
    margin-bottom: 0;
  }
  .popup-promo-video__content {
    min-height: auto;
    padding: 20px 10px;
  }
  .popup-promo-video__button{
    margin-top: 0;
  }
  .popup-promo-video__button .btn {
    /* width: 256px; */
    height: 48px;
  }
}

@media screen and (min-width: 991px) {
  .popup-promo-video__body {
    width: 905px;
    display: flex;
    flex-direction: column;
    min-height: 312px;
  }
  .popup-promo-video__desc {
    margin-bottom: 24px;
  }
  .popup-promo-video__content {
    padding: 52px 30px;
    width: 450px;
  }
  .popup-promo-video__background--no-mob {
    display: block;
  }

  .popup-promo-video__background {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 450px);
    height: 100%;
    object-fit: cover;
  }
  .popup-promo-video__title {
    font-size: 36px;
  }
}

.custom-popup {
  background-color: #ED1C24;
  color: #FFFFFF;
}
</style>
