<template>
  <div
    class="item text-white cursor-flip"
    :class="{ active: isActive }"
    @click="isActive = !isActive"
  >
    <div class="item__inner">
      <div class="item__front">
        <div v-if="item.img" class="item__image">
          <img
            loading="lazy"
            width="420"
            height="560"
            class="img-to-animate"
            :src="item.img"
            :alt="item.title"
          />
        </div>
        <div class="item__content d-flex flex-column justify-content-end">
          <div class="h3 item__title" v-html="item.title" />
          <div v-if="item.time" class="item__time" v-html="item.time" />
        </div>
      </div>
      <div class="item__back">
        <div class="item__image" />
        <div class="item__content d-flex flex-column">
          <div class="h3 item__title" v-html="item.title" />
          <div v-if="item.time" class="item__time" v-html="item.time" />
          <div class="item__text mt-auto" v-html="item.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped>
.item {
  position: relative;
  flex: 0 0 420px;
  height: 560px;
  margin-right: 24px;
  background-color: transparent;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.item:last-child {
  margin-right: 0;
}

.item:nth-child(even) {
  margin-top: 80px;
}

.item__inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.item__front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.item__back {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.item.active .item__inner {
  transform: rotateY(-180deg);
}

.item__image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #205640;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.4) 100%
  );
}

.item__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.item__title {
  margin: 0 0 15px;
  letter-spacing: -0.03em;
}

.item__time {
  font-size: 16px;
}

.item__back .item__image::after {
  background-image: url(~@i/text-mask.png);
  background-position: 50% 50%;
  background-size: 420px 560px;
  opacity: 0.2;
}

.item__back .item__time {
  opacity: 0.6;
}
</style>
