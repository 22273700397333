<template>
  <div class="item text-white">
    <div v-if="item.img" class="item__image">
      <picture>
        <source
          v-if="item.imgsm"
          :srcset="item.imgsm"
          media="(max-width: 767px)"
        />
        <img
          loading="lazy"
          width="496"
          height="660"
          class="img-to-animate"
          :src="item.img"
          :alt="item.title"
        />
      </picture>
    </div>
    <div class="item__content d-flex flex-column">
      <div class="h3 item__title" v-html="item.title" />
      <div class="item__text mt-auto" v-html="item.text" />
      <div v-if="item.link" class="item__more">
        <router-link class="stretched-link more-link" :to="{ path: item.link }">
          Подробнее
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.item {
  position: relative;
  flex: 0 0 496px;
  overflow: hidden;
  max-width: 496px;
}

.swiper-slide .item {
  flex: 0 0 100%;
  max-width: 100%;
}

.item + .item {
  margin-left: 24px;
}

.item__title {
  overflow-wrap: anywhere;
}

.item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.item__image::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0.6) 0%,
    rgb(63 68 64 / 0) 50%,
    rgb(63 68 64 / 0.6) 100%
  );
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__content {
  position: relative;
  min-height: 660px;
  padding: 34px;
}

.item__more {
  margin-top: 23px;
}
@media (max-width: 1279px) {
  .item__content {
    min-height: 595px;
  }
}
@media (max-width: 991px) {
  .item__content {
    min-height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .item__content {
    padding: 24px;
  }

  .item__text {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .item__content {
    min-height: 465px;
  }

  .item__more {
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .item__content {
    min-height: 400px;
    padding: 24px;
  }

  .swiper-slide .item__text {
    font-size: 14px;
    line-height: 1.3;
  }
}
</style>
