export function is_touch() {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };
  if ("ontouchstart" in window || window.DocumentTouch) {
    return true;
  }
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return mq(query);
}

export function lazyVideo(el) {
  if (process.env.VUE_APP_IS_LOCAL == undefined) {
    const lazyVideos = [].slice.call(el.querySelectorAll("video.lazy"));
    if ("IntersectionObserver" in window) {
      const lazyVideoObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (const source in video.target.children) {
              const videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === "string" &&
                videoSource.tagName === "SOURCE"
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }
            video.target.src = video.target.dataset.src;
            video.target.load();
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });
      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  }
}

const helpers = {
  is_touch: is_touch,
  lazyVideo: lazyVideo,
};

export default helpers;
