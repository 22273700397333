<template>
  <section class="home-block">
    <div class="container">
      <div ref="content" class="content">
        <div ref="title" class="title h1">
          <span class="text-masked">VERY</span> вдохновляющий
        </div>
        <div ref="text" class="text">
          <p>
            Получайте удовольствие от&nbsp;современной и&nbsp;лаконичной
            архитектуры комплекса. Благодаря панорамному остеклению
            и&nbsp;светлым текстурам, увеличивается пространство
            и&nbsp;открывается незабываемый вид на&nbsp;Москву.
          </p>
        </div>
      </div>
      <div id="gallery">
        <div v-if="mq.lgPlus" ref="list" class="list">
          <swiper
            v-if="items && items.length"
            class="autoplayed cursor-drag"
            loop
            :autoplay="{ delay: 0 }"
            :speed="10000"
            free-mode
            :free-mode-momentum-ratio="0.7"
            :free-mode-momentum-velocity-ratio="0.7"
            :slides-per-view="'auto'"
            :space-between="24"
            :preload-images="false"
            :lazy="{
              loadPrevNext: true,
              loadPrevNextAmount: chunksList.length,
            }"
            @swiper="onSwiper"
            @autoplayStop="onAutoplayStop"
            @autoplayStart="onAutoplayStart"
          >
            <swiper-slide v-for="(chunk, i) in chunksList" :key="i">
              <GalleryItem
                v-for="(item, j) in chunk"
                :key="j"
                :item="item"
                @showGallery="showGallery"
              />
            </swiper-slide>
          </swiper>
          <swiper
            v-else
            class="autoplayed cursor-drag"
            loop
            :autoplay="{ delay: 0 }"
            :speed="10000"
            free-mode
            :free-mode-momentum-ratio="0.7"
            :free-mode-momentum-velocity-ratio="0.7"
            :slides-per-view="'auto'"
            :space-between="24"
          >
            <swiper-slide v-for="(chunk, i) in 10" :key="i">
              <GalleryItem v-for="(item, j) in 2" :key="j" />
            </swiper-slide>
          </swiper>
        </div>
        <div v-else-if="items && items.length" class="mobile-list">
          <div v-for="(array, i) in mobileList" :key="i" class="row">
            <GalleryItemMobile
              v-for="(item, j) in array"
              :key="j"
              :item="item"
              :class="'col-' + (item.type == 'text' ? '12' : '6')"
              @showGallery="showGallery"
            />
          </div>
        </div>
        <div
          ref="bottom"
          class="btns-list d-flex d-md-none justify-content-center"
        >
          <div class="btns-list__item">
            <router-link :to="{ name: 'Gallery' }" class="btn btn-default">
              Посмотреть полную галерею
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GalleryItem from "@/components/home/GalleryItem.vue";
import GalleryItemMobile from "@/components/home/GalleryItemMobile.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";
SwiperCore.use([Autoplay]);
import _chunk from "lodash/chunk";
import _shuffle from "lodash/shuffle";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { is_touch } from "@/helpers.js";
export default {
  components: {
    Swiper,
    SwiperSlide,
    GalleryItem,
    GalleryItemMobile,
  },
  inject: ["mq", "galleryStore"],
  data() {
    return {
      swiper: null,
      images: [],
      items: [],
    };
  },
  computed: {
    filteredList() {
      let items = _shuffle(this.items);
      this.getImages(items);
      return items;
    },
    chunksList() {
      return _chunk(this.filteredList, 2);
    },
    mobileList() {
      let texts = _shuffle(
        this.filteredList.filter((item) => {
          return item.type == "text";
        })
      );
      let array = [
        [...this.images.slice(0, 4)],
        [...texts.slice(0, 1)],
        [...this.images.slice(4, 6)],
        [...texts.slice(1, 2)],
        [...this.images.slice(6, 10)],
      ].filter((e) => e.length);
      return array;
    },
  },
  watch: {
    galleryStore: {
      handler() {
        this.initItems();
      },
      deep: true,
    },
  },
  created() {
    this.initItems();
  },
  mounted() {
    this.initGsap();
  },
  methods: {
    initItems() {
      this.items = this.galleryStore.state.data?.content[0]?.body?.blocks.map(
        (block) => {
          const data = block?.data[0];
          return {
            type: block.type,
            img: data.source,
            text: data.caption,
            image_desktop: data.image_desktop,
            image_tablet: data.image_tablet,
            image_mobile: data.image_mobile,
            image_thumb: data.image_thumb,
          };
        }
      );
    },
    initGsap() {
      if (!is_touch()) {
        this.$nextTick(() =>
          gsap.from(
            gsap.utils.toArray([
              this.$refs.title,
              this.$refs.text,
              this.$refs.list,
            ]),
            {
              y: 150,
              stagger: { each: 0.1 },
              scrollTrigger: {
                trigger: this.$refs.content,
                start: "top bottom",
                end: "bottom 40%",
                scrub: 1.5,
              },
            }
          )
        );
      }
    },
    getImages(items) {
      let index = 0;
      items.forEach((item) => {
        if (item.type == "image") {
          item.index = index;
          this.images.push({
            src: item.img,
            image_desktop: item.image_desktop,
            image_tablet: item.image_tablet,
            image_mobile: item.image_mobile,
            image_thumb: item.image_thumb,
            text: item.text,
            index: index,
          });
          index++;
        }
      });
    },
    onSwiper(swiper) {
      this.swiper = swiper;
      this.$nextTick(() => {
        this.swiper.autoplay.stop();
        ScrollTrigger.create({
          trigger: this.$refs.list,
          onEnter: () => {
            if (this.swiper.autoplay !== undefined) {
              this.swiper.autoplay.start();
            }
          },
          onEnterBack: () => {
            if (this.swiper.autoplay !== undefined) {
              this.swiper.autoplay.start();
            }
          },
          onLeave: () => {
            if (this.swiper.autoplay !== undefined) {
              this.swiper.autoplay.stop();
            }
          },
          onLeaveBack: () => {
            if (this.swiper.autoplay !== undefined) {
              this.swiper.autoplay.stop();
            }
          },
          start: "top bottom",
          end: "bottom top",
        });
      });
    },
    onAutoplayStop() {
      if (this.swiper !== null) {
        if (!this.swiper.destroyed) {
          this.swiper.$el[0].classList.remove("autoplayed");
          this.swiper.params.speed = 300;
        }
      }
    },
    onAutoplayStart() {
      if (this.swiper !== null) {
        if (!this.swiper.destroyed) {
          this.swiper.$el[0].classList.add("autoplayed");
          this.swiper.params.speed = 10000;
        }
      }
    },
    showGallery(index) {
      this.$vfm.show({
        component: "PopupGallery",
        bind: {
          title: "Галерея",
          img: this.images,
          index: index,
        },
      });
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
}

.title span {
  display: block;
}

.text {
  max-width: 630px;
  margin: 0 auto;
  text-align: center;
}

.list {
  margin: 60px 0 0;
}

.list :deep(.swiper-slide) {
  width: 392px;
}

.list .swiper-container.autoplayed :deep(.swiper-wrapper) {
  transition-timing-function: linear;
}

.list .swiper-container {
  overflow: inherit;
}

.list :deep(.swiper-slide:nth-child(3n + 1)) {
  margin-top: 100px;
}

.list :deep(.swiper-slide:nth-child(3n + 2)) {
  margin-top: 240px;
}

.mobile-list {
  margin-top: 50px;
  padding-bottom: 35px;
}

.mobile-list > .row {
  margin-right: -8px;
  margin-left: -8px;
}

.mobile-list > .row > div {
  padding-right: 8px;
  padding-left: 8px;
}

.mobile-list .item:nth-child(even) :deep(.item-image) {
  top: 50px;
}

.btns-list {
  margin-top: 55px;
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 25px;
  }

  .mobile-list {
    margin-top: 35px;
  }

  .btns-list {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .btns-list {
    margin-top: 32px;
  }
}
@media (max-width: 374px) {
  .title {
    font-size: 46px;
  }
}
</style>
