import axios from "axios";
import { reactive } from "vue";
const mainStore = {
  state: reactive({
    data: null,
  }),
  setData() {
    axios
      .get(process.env.VUE_APP_API_STORAGE + process.env.VUE_APP_PROJECT_ID)
      .then(({ data }) => 
          {
            return this.state.data = data.data
          }
        )
      .catch(() => {});
  },
  getMortgageProps () {
    return {
      apartment: {
        initialPayment: 27,
        minPayment: 27,
        calcBet: 9.99
      },
      flat: {
        initialPayment: 20.1,
        minPayment: 20.1,
        calcBet: 5.8
      },
    }
  },
};

mainStore.setData();
export default mainStore;
