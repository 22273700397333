<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="vfm-video"
    :lock-scroll="lockScroll"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="very-aparts-popup">
      <a href="javascript:void(0)" class="vfm__close very-aparts-popup__close" @click="close"
      ><Icon icon="close"/>
      </a>
      <div class="very-aparts-popup__video">
        <video
          preload="none"
          autoplay
          muted
          loop
          playsinline
          ref="video"
        >
          <source
            type="video/mp4"
            src="/apartBanner.mp4"
          />
        </video>
      </div>
      <div class="very-aparts-popup__bottom">
        <button
          class="btn btn-aparts-popup btn-small"
          @click="() => {handler();close()}"
        >
          Получить предложение
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: 'veryApartsPopup',
  mounted () {
    this.$nextTick(() => {
      this.$refs.video.play()
    })
  },
  methods: {
    handler () {
      window.popupWithChats.showCallback({
        title: 'Получить предложение'
      })
    },

    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  }
}
</script>

<style>
.very-aparts-popup {
  position: relative;
  width: calc(100% - 30px);
  max-width: 600px;
  margin: auto;
  border-radius: 6px;
  overflow: hidden;
  background-color: #024b3b;
}
.btn-aparts-popup {
  background: #FFFFFF;
  border-color: #fff;
  color: #205640;
  padding: 10px 50px;
}
.very-aparts-popup__bottom {
  text-align: center;
  padding: 15px;
}
.very-aparts-popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.very-aparts-popup__close img {
  width: 100%;
}
.very-aparts-popup__video video {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 500px) {
  .btn-aparts-popup {
    padding: 10px 30px;
    font-size: 20px;
  }
}
</style>
