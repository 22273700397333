<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    name="booklet"
    @opened="opened"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div v-show="formOk" class="text-center">
        <div class="h4">Спасибо за&nbsp;запрос</div>
        <p>
          Буклет можно посмотреть
          <a :href="bookletLink" target="_blank"><strong>здесь</strong></a
          >.
        </p>
        <button class="btn btn-default btn-small w-100" @click="close">
          Закрыть
        </button>
      </div>
      <div v-show="!formOk" :class="{ sending: sending }">
        <div class="h4" v-html="title" />

        <p>Введите ваш e-mail и&nbsp;получите буклет о&nbsp;проекте.</p>

        <form class="form" novalidate @submit.prevent="submit">
          <div class="form__row">
            <Input
              ref="name"
              v-model="form.name"
              type="text"
              required
              placeholder="Ваше имя *"
              aria-label="Ваше имя"
              name="name"
            />
          </div>
          <div class="form__row">
            <Input
              ref="phone"
              v-model="form.phone"
              v-maska="'+7 (###) ###-##-##'"
              type="tel"
              required
              placeholder="Ваш телефон *"
              aria-label="Ваш телефон"
              inputmode="tel"
              name="phone"
              @focus="if (form.phone == '') form.phone = '+7 (';"
              @blur="if (form.phone == '+7 (') form.phone = '';"
            />
          </div>
          <div class="form__row">
            <Input
              ref="email"
              v-model="form.email"
              type="email"
              name="email"
              required
              placeholder="Ваш e-mail *"
              aria-label="Ваш e-mail"
            />
          </div>
          <div class="form__row">
            <Agree ref="agree" v-model="form.agree" />
          </div>
          <div class="form__row mb-0">
            <button class="btn btn-default btn-small w-100" type="submit">
              {{ sending ? "Отправляется" : "Отправить" }}
            </button>
          </div>
        </form>
        <a href="javascript:void(0)" class="vfm__close" @click="close"
          ><Icon icon="close"
        /></a>
      </div>
      <div v-if="globalError" class="text-center mt-3" style="color: red">
        Не было отправлено!<br />
        Позвоните нам {{ phone }}
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import Input from "@/components/UI/Input.vue";
import Agree from "@/components/UI/Agree.vue";
import _forEach from "lodash/forEach";
import _filter from "lodash/filter";
import coMagicOfflineRequest from "@/coMagicOfflineRequest";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    Input,
    Agree,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Скачать буклет",
    },
  },
  inject: ["mainStore"],
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        url: "",
        agree: true,
      },
      formOk: false,
      sending: false,
      globalError: false,
    };
  },
  computed: {
    phone() {
      return this.mainStore.state.data?.addresses.addr_list[0].addr_phone;
    },
    bookletLink() {
      return this.mainStore.state.data?.pdf_prresentation.pdf_presentation;
    },
  },
  watch: {
    $route(to) {
      this.form.url = to.fullPath;
    },
  },
  methods: {
    beforeOpen(event) {
      this.formOk = false;
      this.sending = false;
      this.globalError = false;
      _forEach(this.$refs, (ref) => {
        ref.status = "init";
      });
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
    opened() {
      if (!is_touch()) {
        this.$refs.name.$el.focus();
      }
    },
    submit() {
      if (!this.sending) {
        _forEach(this.$refs, (ref) => {
          ref.validate();
        });
        const errors = _filter(this.$refs, (ref) => {
          return ref.status == "error";
        });
        if (errors.length == 0) {
          this.sending = true;
          if (window.Comagic) {
            coMagicOfflineRequest(this.form, this.title, "")
              .then(() => {
                this.form.name = "";
                this.form.email = "";
                this.form.phone = "";
                this.formOk = true;
                this.sending = false;
                setTimeout(() => {
                  this.$vfm.hideAll();
                }, 5000);
              })
              .catch(() => {
                this.globalError = true;
              })
              .finally(() => {});
          }
        }
      }
    },
  },
};
</script>
