<template>
  <section class="home-block py-0 text-white">
    <div class="background">
      <div ref="background" class="video-background">
        <img
          :src="require('@i/video/home-questions.jpg')"
          loading="lazy"
          width="1920"
          height="1080"
          alt=""
        />
        <video
          class="lazy"
          autoplay
          muted
          loop
          playsinline
          preload="none"
          :data-src="require('@i/video/home-questions.mp4')"
          src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        />
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 offset-lg-1 col-lg-8 col-md-10">
            <div ref="title" class="title h1">Покажем всё VERY подробно</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div ref="text" class="text">
              <p>
                VERY&nbsp;&mdash; абсолютно уникальный проект для Москвы.
                Мы&nbsp;постарались передать вам атмосферу и&nbsp;невероятные
                возможности этого удивительного места. Свяжитесь с&nbsp;нами для
                ответов на&nbsp;все вопросы и&nbsp;станьте ещё на&nbsp;шаг ближе
                к&nbsp;мечте.
              </p>
            </div>
            <div ref="bottom" class="btns-list d-md-flex">
              <div class="btns-list__item">
                <button class="btn btn-lime" data-popup-with-chats data-popup-with-chats-title="Связаться со&nbsp;специалистом">
                  Связаться с менеджером
                </button>
              </div>
              <div class="btns-list__item">
                <router-link
                  :to="{ name: 'PlanArchive' }"
                  class="btn btn-transparent"
                >
                  Выбрать квартиру
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  mounted() {
    lazyVideo(this.$el);

    if (!is_touch()) {
      gsap.fromTo(
        this.$refs.background,
        { y: "-25%" },
        {
          y: "25%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: true,
          },
          ease: "none",
        }
      );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 70%",
            end: "bottom bottom",
            scrub: 1.5,
            //markers: true,
          },
        })
        .from(
          gsap.utils.toArray([
            this.$refs.title,
            this.$refs.text,
            this.$refs.bottom,
          ]),
          {
            y: 150,
            stagger: { each: 0.1 },
          },
          0
        );
    }
  },
};
</script>

<style scoped>
body.-notouch .video-background {
  top: -25%;
  bottom: -25%;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(104 116 69 / 0.4);
}

.content {
  position: relative;
  padding: 110px 0;
}

.text {
  max-width: 580px;
}
@media (max-width: 767px) {
  .content {
    padding: 65px 0;
    text-align: center;
  }

  .btns-list__item {
    margin-bottom: 10px;
  }

  .btns-list__item:last-child {
    margin-bottom: 0;
  }

  .title {
    font-size: 88px;
  }
}
@media (max-width: 575px) {
  .title {
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    font-size: 64px;
  }
}
@media (max-width: 397px) {
  .title {
    max-width: 300px;
  }
}
@media (max-width: 374px) {
  .btns-list__item .btn {
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>
