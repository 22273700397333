<template>
  <section class="home-block">
    <div ref="particle" class="particle" />
    <div class="container">
      <div ref="title" class="title h1">
        <span class="text-masked">Шикарный</span>
        вид на день
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-7">
          <div ref="image" class="image image-with-text">
            <div
              ref="video"
              class="video-background"
              :style="{
                backgroundImage:
                  'url(' + require('@i/video/home-coffee.jpg') + ')',
              }"
            >
              <video
                class="lazy"
                autoplay
                muted
                loop
                playsinline
                preload="none"
                :data-src="require('@i/video/home-coffee.mp4')"
                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              />
            </div>
            <div class="image-with-text__text text-right">
              Панорамные окна в каждой квартире
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5">
          <div class="content">
            <div ref="text" class="text">
              <p>
                Природа и&nbsp;красота: гости заходят в&nbsp;ваш дом,
                и&nbsp;им&nbsp;открываются окна в&nbsp;пол высотой
                2,46&nbsp;метра, с&nbsp;великолепными панорамами Главного
                и&nbsp;Малого Ботанических садов, Останкинского парка, ВДНХ,
                живописного Марфино.
              </p>
            </div>
            <div ref="view" class="view">
              <strong>Виды вашего окружения:</strong>
              <ul class="toolbar cursor-zoom">
                <li v-for="(item, i) in img" :key="item" class="toolbarItem">
                  <img
                    loading="lazy"
                    width="40"
                    height="40"
                    :src="item.thumb"
                    alt="Вид из окна"
                    @click.prevent="
                      $vfm.show({
                        component: 'PopupGallery',
                        bind: {
                          title: 'Виды вашего окружения',
                          img: img,
                          index: i,
                        },
                      })
                    "
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  components: {},
  data() {
    return {
      img: [
        {
          src: require("@i/html/home/bird-3-1.jpg"),
          thumb: require("@i/html/home/bird-3-t.jpg"),
        },
        {
          src: require("@i/html/home/bird-4-1.jpg"),
          thumb: require("@i/html/home/bird-4-t.jpg"),
        },
        {
          src: require("@i/html/home/bird-5-1.jpg"),
          thumb: require("@i/html/home/bird-5-t.jpg"),
        },
        {
          src: require("@i/html/home/bird-2-1.jpg"),
          thumb: require("@i/html/home/bird-2-t.jpg"),
        },
        {
          src: require("@i/html/home/bird-1-1.jpg"),
          thumb: require("@i/html/home/bird-1-t.jpg"),
        },
      ],
    };
  },
  mounted() {
    lazyVideo(this.$el);

    const icons = this.$el.querySelectorAll(".toolbarItem");

    if (!is_touch()) {
      gsap.from(
        gsap.utils.toArray([
          this.$refs.title,
          this.$refs.text,
          this.$refs.view,
        ]),
        {
          y: 150,
          stagger: { each: 0.1 },
          scrollTrigger: {
            trigger: this.$el,
            start: "top 60%",
            end: "center center",
            scrub: 1.5,
          },
        }
      );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            scrub: 1.5,
          },
        })
        .from(this.$refs.image, { y: -100, ease: "expo.inOut" }, 0)
        .from(this.$refs.image, { y: -100, ease: "expo.inOut" }, 0)
        .fromTo(this.$refs.video, { y: -100 }, { y: 100 }, 0)
        .from(this.$refs.particle, { y: 150 }, 0);

      const iconsImg = this.$el.querySelectorAll(".toolbarItem img");
      if (!(icons && icons.length > 0 && iconsImg && iconsImg.length > 0)) {
        return;
      }

      gsap
        .timeline()
        .to(
          gsap.utils.toArray(icons),
          {
            duration: 1,
            y: -10,
            stagger: {
              each: 0.2,
              yoyo: true,
              repeat: -1,
              //from: 'center',
            },
            ease: "slow(0.1, 0.1, true)",
          },
          0
        )
        .to(
          gsap.utils.toArray(iconsImg),
          {
            duration: 1,
            scale: 1.5,
            stagger: {
              each: 0.2,
              yoyo: true,
              repeat: -1,
              //from: 'center',
            },
            ease: "slow(0.1, 0.1, true)",
          },
          0
        );

      // const icons = document.querySelectorAll('.toolbarItem');
      const dock = document.querySelector(".toolbar");
      const firstIcon = icons[0];

      const min = 48;
      const max = 68;
      const bound = min * Math.PI;

      gsap.set(icons, {
        transformOrigin: "50% 50%",
      });

      dock.addEventListener("mousemove", (event) => {
        const offset = dock.getBoundingClientRect().left + firstIcon.offsetLeft;
        updateIcons(event.clientX - offset);
      });

      dock.addEventListener("mouseleave", (event) => {
        gsap.to(icons, {
          duration: 0.3,
          scale: 1,
          x: 0,
        });
      });

      // eslint-disable-next-line no-inner-declarations
      function updateIcons(pointer) {
        for (let i = 0; i < icons.length; i++) {
          const icon = icons[i];
          const distance = i * min + min / 2 - pointer;
          let x = 0;
          let scale = 1;

          if (-bound < distance && distance < bound) {
            const rad = (distance / min) * 0.5;
            scale = 1 + (max / min - 1) * Math.cos(rad);
            x = 2 * (max - min) * Math.sin(rad);
          } else {
            x = (-bound < distance ? 2 : -2) * (max - min);
          }

          gsap.to(icon, {
            duration: 0.3,
            x: x,
            scale: scale,
          });
        }
      }
    } else {
      gsap.timeline().to(
        gsap.utils.toArray(icons),
        {
          duration: 1.5,
          scale: 1.08,
          stagger: {
            each: 0.2,
            yoyo: true,
            repeat: -1,
            from: "random",
          },
          ease: "slow(0.1, 0.1, true)",
        },
        0
      );
    }
  },
};
</script>

<style scoped>
.home-block {
  padding-bottom: 0;
}

.particle {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 440px;
  height: 498px;
  margin-left: 155px;
  background: url(~@i/bg-coffee.svg) 0 0 no-repeat;
}

.title {
  position: relative;
  z-index: 1;
  margin: 0;
  margin-bottom: -160px;
  text-align: right;
}

.title span {
  display: block;
}

.content {
  padding-top: 230px;
}

.image {
  position: relative;
  right: 37px;
  overflow: hidden;
  height: 700px;
  margin-left: -65px;
}

.view {
  margin-top: 80px;
}

.view strong {
  display: block;
  margin-bottom: 30px;
  font-weight: 500;
}

.view ul {
  position: relative;
  left: -4px;
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.view ul li {
  display: block;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 0 4px;
  padding: 0;
  border-radius: 50%;
}

.view ul li:last-child {
  margin-right: 0;
}

.view ul li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 1279px) {
  .image {
    height: 600px;
  }

  .view {
    margin-top: 50px;
  }

  .particle {
    margin-left: 105px;
  }

  .home-block {
    padding-bottom: 60px;
  }
}
@media (max-width: 991px) {
  .image {
    height: 500px;
  }

  .title {
    margin-bottom: -70px;
  }

  .content {
    padding-top: 110px;
  }

  .view {
    margin-top: 40px;
  }

  .particle {
    margin-left: 0;
  }

  .home-block {
    padding-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 32px;
    font-size: 88px;
    text-align: center;
  }

  .content {
    padding: 0;
    text-align: center;
  }

  .image {
    right: auto;
    height: auto;
    margin: 0 0 35px;
    padding-bottom: 80%;
  }

  .view {
    margin-top: 25px;
  }

  .view strong {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .view ul {
    left: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view ul li {
    flex: 0 0 auto;
  }

  .toolbarItem:nth-child(1) {
    width: 60px;
    height: 60px;
  }

  .toolbarItem:nth-child(2) {
    width: 110px;
    height: 110px;
  }

  .toolbarItem:nth-child(3) {
    width: 54px;
    height: 54px;
  }

  .toolbarItem:nth-child(4) {
    width: 74px;
    height: 74px;
  }

  .toolbarItem:nth-child(5) {
    width: 44px;
    height: 44px;
  }

  .particle {
    bottom: -80px;
    margin-left: -226px;
    transform: scale(0.8);
  }

  .home-block {
    padding-bottom: 45px;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 64px;
  }

  .image {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 374px) {
  .image-with-text__text {
    padding: 20px;
  }
}
</style>
