<template>
  <section class="home-block py-0 text-white">
    <div class="background">
      <div ref="background" class="video-background">
        <picture>
          <source
            :srcset="require('@i/video/home-family-s.jpg')"
            media="(max-width: 767px)"
          />
          <img
            :src="require('@i/video/home-family.jpg')"
            loading="lazy"
            width="1920"
            height="1013"
            alt=""
          />
        </picture>
        <video
          class="lazy"
          autoplay
          muted
          loop
          playsinline
          preload="none"
          :data-src="require('@i/video/home-family.mp4')"
          src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        />
      </div>
    </div>
    <div ref="particle" class="particle" />
    <div class="content">
      <div class="container">
        <div ref="title" class="title h1">Яркая семейная жизнь</div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  mounted() {
    lazyVideo(this.$el);

    if (!is_touch()) {
      gsap.fromTo(
        this.$refs.background,
        { y: "-25%" },
        {
          y: "25%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: true,
          },
          ease: "none",
        }
      );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 60%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([this.$refs.title]),
          {
            y: 250,
            stagger: { each: 0.1 },
          },
          0
        )
        .from(
          this.$refs.particle,
          {
            autoAlpha: 0,
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.home-block {
  height: 760px;
}

body.-notouch .video-background {
  top: -25%;
  bottom: -25%;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(63 68 64 / 0.2);
}

.particle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 957px;
  height: 818px;
  margin-top: -409px;
  margin-left: -210px;
  background: url(~@i/bg-family.svg) 0 0 no-repeat;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 63px 0;
}

.title {
  max-width: 500px;
  margin: 0;
}
@media (max-width: 1279px) {
  .home-block {
    height: 685px;
  }

  .particle {
    transform: scale(0.9);
  }
}
@media (max-width: 991px) {
  .home-block {
    height: 610px;
  }

  .particle {
    margin-left: -480px;
    transform: scale(0.8);
  }
}
@media (max-width: 767px) {
  .home-block {
    height: 535px;
  }

  .content {
    justify-content: flex-end;
    text-align: center;
  }

  .title {
    max-width: 390px;
    margin-right: auto;
    margin-left: auto;
    font-size: 88px;
  }

  .particle {
    opacity: 0.5;
    transform: scale(0.7);
  }
}
@media (max-width: 575px) {
  .home-block {
    height: 460px;
  }

  .title {
    font-size: 64px;
  }

  .particle {
    transform: scale(0.6);
  }
}
</style>
