<template>
  <div ref="plan" class="plan">
    <img
      v-if="floor?.plan_image"
      :src="floor.plan_image.source"
      :width="floor.plan_image.width"
      :height="floor.plan_image.height"
      loading="lazy"
      alt="План этажа"
      :style="{
        backgroundImage: 'url(' + floor.plan_image.image_thumb + ')',
      }"
      @load="mainImageLoaded = true"
    />
    <div v-if="floor?.flats?.length > 0" class="tooltip__wrap">
      <svg
        v-if="mainImageLoaded"
        class="tooltip__area"
        :viewBox="
          '0 0 ' + floor.plan_image.width + ' ' + floor.plan_image.height
        "
      >
        <polygon
          v-for="flat in floor.flats"
          :key="flat.number"
          :class="{ current: flat.isCurrent, active: flat.isActive }"
          :points="flat.area_points"
          @click="onClick(flat)"
          @mouseenter="onmouseEnter($event, flat)"
          @mouseleave="flat.isActive = false"
          @mousemove="pathMove($event, flat)"
        />
      </svg>
      <div
        v-for="flat in floor.flats"
        :key="flat.id"
        :style="{
          transform: 'translate3d(' + flat.x + 'px, ' + flat.y + 'px, 0px)',
        }"
        class="tooltip__dot"
        :class="{ active: flat.isActive }"
      >
        <div class="popup tooltip__popup">
          <div class="popup__title">
            {{ $filters.flatRoomNormalize(flat.layout) }}, №{{ flat.number }}
          </div>
          <div v-if="flat.layout.area" class="popup__text">
            Общая площадь
            <span v-html="$filters.squareFormat(flat.layout.area)" />
          </div>
        </div>
      </div>
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import axios from "axios";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";

export default {
  props: {
    img: {
      require: false
    },
    points: {
      require: false
    },
  },
  data() {
    return {
      floor: null,
      mainImageLoaded: false,
    };
  },
  computed: {
    animateReady() {
      const { floor, mainImageLoaded } = this;
      return {
        floor,
        mainImageLoaded,
      };
    },
  },
  watch: {
    animateReady: {
      handler: function (val) {
        if (val.floor && val.mainImageLoaded) {
          this.$nextTick(() => {
            const polygons = this.$refs.plan.querySelectorAll(
              ".tooltip__area polygon"
            );
            if (polygons.length > 0) {
              gsap.fromTo(
                polygons,
                {
                  opacity: 0,
                  scale: 0.9,
                  transitionDuration: 0,
                  transformOrigin: "center center",
                },
                {
                  opacity: 0.2,
                  scale: 1,
                  duration: 0.5,
                  clearProps: "all",
                  stagger: { each: 0.15 },
                }
              );
            }
          });
        }
      },
    },
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint +
          "building-objects/" +
          this.$project +
          "/floor/" +
          this.$route.params.korp +
          "/1/" +
          this.$route.params.floor
      );
      const data = response.data.data;
      const out = [];
      if (data && data.flats) {
        data.flats.forEach((item) => {
          if (item.status !== "unavailable") {
            item.isCurrent = item.uuid === this.$route.params.id;
            out.push(item);
          }
        });
        out.sort((a, b) =>
          a.uuid === this.$route.params.id ? -1 : a.number - b.number
        );
      }
      data.flats = out;
      this.floor = data;
    } catch (err) {
      this.floor = {
        plan_image: this.img
      }
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
  methods: {
    onmouseEnter(e, flat) {
      if (!is_touch()) {
        this.pathMove(e, flat);
        flat.isActive = true;
      }
    },
    onClick(flat) {
      this.$emit("flatClick", flat);
      this.$router.push({
        name: "PlanFlat",
        params: {
          korp: this.floor.building,
          sec: this.floor.section,
          floor: this.floor.number,
          fnumb: flat.number_on_floor,
          numb: flat.number,
          quantity: flat.layout?.room_count,
          id: flat.uuid,
        },
      });
    },
    pathMove(e, flat) {
      if (!is_touch()) {
        flat.x = e.offsetX;
        flat.y = e.offsetY;
      }
    },
  },
};
</script>

<style scoped>
.plan {
  position: relative;
  padding-bottom: 70.7376%;
}

.plan img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.tooltip__area polygon {
  fill: #2a8632;
}

.popup {
  bottom: 25px;
  padding: 20px 25px 23px;
  box-shadow: 0 10px 40px rgb(0 0 0 / 0.2);
}

.popup__title {
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 20px;
}

.popup__title:last-child {
  margin-bottom: 0;
}

.popup__text {
  font-size: 16px;
}

.popup__text span {
  font-weight: normal;
}
</style>
