import { reactive } from "vue";
const favouritesStore = {
  state: reactive({
    favourites: localStorage.getItem("favourites")
      ? JSON.parse(localStorage.getItem("favourites"))
      : [],
  }),
  toggleFavourites(id) {
    const index = this.state.favourites.indexOf(id);
    if (index !== -1) {
      this.state.favourites.splice(index, 1);
    } else {
      this.state.favourites.push(id);
    }
    const parsed = JSON.stringify(this.state.favourites);
    localStorage.setItem("favourites", parsed);
  },
};
export default favouritesStore;
