<template>
  <vue-final-modal
    v-if="dateActive"
    v-slot="{ close }"
    name="popup-promo-countdown"
    esc-to-close
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="popup-promo-countdown">
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
      <div class="popup-promo-countdown__left">
        <div class="popup-promo-countdown__content">
          <div class="popup-promo-countdown__title">
            Квартиры с выгодой <br />до <span>3 200 000 ₽</span>
          </div>
          <div class="popup-promo-countdown__subtitle">
            Акция действительна&nbsp;<span>до 25.11.2023 г.</span>
          </div>
          <CountdownGroup :date="date" />
          <button
            class="btn btn-default"
            data-popup-with-chats
            data-popup-with-chats-title="Узнать подробнее"
            @click="close"
          >
            Узнать подробнее
          </button>
        </div>
      </div>
      <div class="popup-promo-countdown__right">
        <picture>
          <source
            srcset="@i/popup-promo-countdown.jpg"
            media="(min-width: 992px)"
          />
          <img src="@i/popup-promo-countdown_mob.jpg" alt="" />
        </picture>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import CountdownGroup from "@/components/CountdownGroup.vue";

export default {
  components: { CountdownGroup },
  data() {
    return {
      date: new Date(2023, 10, 26),
    };
  },
  computed: {
    dateActive() {
      return this.date > new Date();
    },
  },
  methods: {
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped>
.popup-promo-countdown {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background-color: #fff;
}

::v-deep .vfm__close {
  color: #fff;
}

::v-deep .vfm__content::before,
::v-deep .vfm__content::after {
  display: none;
}

.popup-promo-countdown__left {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 10px;
}

.popup-promo-countdown__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup-promo-countdown__right img {
  display: block;
  object-fit: cover;
  width: 100%;
}

.popup-promo-countdown__title {
  font-size: 36px;
  line-height: 1.11;
  letter-spacing: -1px;
  font-family: Oranienbaum, "Times New Roman", sans-serif;
  margin-bottom: 16px;
}

.popup-promo-countdown__subtitle {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
}

.popup-promo-countdown__title span {
  color: rgba(80, 156, 59, 1);
}

.popup-promo-countdown .countdown-group {
  max-width: 318px;
  margin: 24px 0;
}

.btn {
  margin-top: auto;
}

@media (max-width: 767px) {
  .btn {
    max-width: 256px;
  }
}

@media (min-width: 768px) {
  .btn {
    height: 58px;
  }
}

@media (min-width: 992px) {
  .popup-promo-countdown {
    flex-direction: row;
  }

  .popup-promo-countdown__left {
    padding: 20px 30px;
  }

  .popup-promo-countdown__right img {
    height: 100%;
  }
}
</style>
