<template>
  <swiper
    :slides-per-view="1"
    :space-between="0"
    :effect="is_touch ? null : 'fade'"
    :preload-images="false"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide
      v-for="(img, i) in item.img"
      :key="img.src"
      @click="
        $vfm.show({
          component: 'PopupGallery',
          bind: {
            title: item.title,
            img: item.img,
            index: i,
          },
        })
      "
    >
      <img
        :class="{ 'img-to-animate': i == 0 }"
        loading="lazy"
        width="600"
        height="420"
        :src="img.src"
        :alt="img.text ? img.text : item.title"
      />
    </swiper-slide>
  </swiper>
  <div ref="nav" class="nav">
    <a
      v-for="(img, i) in item.img"
      :key="img"
      :class="{ active: i == 0 }"
      @mouseenter="mouseEnter(i)"
      @click.prevent="
        $vfm.show({
          component: 'PopupGallery',
          bind: {
            title: item.title,
            img: item.img,
            index: i,
          },
        })
      "
      ><span
    /></a>
  </div>
</template>

<script>
import { is_touch } from "@/helpers.js";
import _forEach from "lodash/forEach";
import SwiperCore, { EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([EffectFade]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      is_touch: is_touch(),
      swiper: null,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    mouseEnter(i) {
      this.swiper.slideTo(i);
    },
    onSlideChange() {
      _forEach(this.$refs["nav"].children, (element) => {
        element.classList.remove("active");
      });
      this.$refs["nav"].children[this.swiper.activeIndex].classList.add(
        "active"
      );
    },
  },
};
</script>
<style scoped>
.swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-container img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.swiper-slide::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 118px;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.5) 100%
  );
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
}

.nav > a {
  position: relative;
  flex-grow: 1;
}

.nav > a span {
  position: absolute;
  right: 4px;
  bottom: 16px;
  left: 4px;
  display: block;
  height: 2px;
  background: rgb(255 255 255 / 0.6);
}

.nav > a span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #509c3b;
  opacity: 0;
}

.nav > a.active span::after {
  opacity: 1;
}

.nav > a:first-child span {
  left: 12px;
}

.nav > a:last-child span {
  right: 12px;
}

body.-istouch .nav {
  pointer-events: none;
}
</style>
