<template>
  <div class="map" ref="map">
    <div ref="helper" class="swiper-helper d-lg-none">
      <div class="swiper-helper__inner" />
    </div>
    <div class="wrap">
      <div class="img">
        <img
          :src="require('@i/road-map.svg')"
          width="2161"
          height="1050"
          loading="lazy"
          alt="Карта окружения"
        />
      </div>
      <img
        :src="require('@i/marker.png')"
        class="marker"
        width="56"
        height="61"
        loading="lazy"
        alt="Very"
        ref="marker"
      />
      <div class="paths">
        <div
          class="path"
          v-for="dot in dots"
          :key="dot.title"
          :class="{ active: dot.isActive }"
        >
          <img
            :src="dot.path"
            width="200"
            height="200"
            loading="lazy"
            :alt="'Путь от ' + dot.title"
          />
        </div>
      </div>
      <div class="dots">
        <div
          class="dot"
          v-for="dot in dots"
          :key="dot.title"
          :class="{ active: dot.isActive }"
        >
          <div class="dot__icon" @click="itemControl(dot.title)">
            <img
              :src="dot.icon"
              :width="dot.iconWidth"
              :height="dot.iconHeight"
              loading="lazy"
              :alt="'Иконка ' + dot.title"
            />
          </div>
          <div class="dot__title" v-html="dot.title"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { is_touch } from "@/helpers.js";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  inject: ["mq"],
  data() {
    return {
      dots: [
        {
          title: "м. Окружная",
          icon: require("@i/icon-map-1.svg"),
          iconWidth: 24,
          iconHeight: 17,
          path: require("@i/path-map-1.svg"),
          isActive: false,
        },
        {
          title: "м. Владыкино",
          icon: require("@i/icon-map-1.svg"),
          iconWidth: 24,
          iconHeight: 17,
          path: require("@i/path-map-2.svg"),
          isActive: false,
        },
        {
          title: "м. Фонвизинская",
          icon: require("@i/icon-map-1.svg"),
          iconWidth: 24,
          iconHeight: 17,
          path: require("@i/path-map-3.svg"),
          isActive: false,
        },
        {
          title: "Алтуфьевское<br> шоссе",
          icon: require("@i/icon-map-2.svg"),
          iconWidth: 32,
          iconHeight: 32,
          path: require("@i/path-map-4.svg"),
          isActive: false,
        },
        {
          title: "Дмитровское шоссе",
          icon: require("@i/icon-map-2.svg"),
          iconWidth: 32,
          iconHeight: 32,
          path: require("@i/path-map-5.svg"),
          isActive: false,
        },
        {
          title: "Третье Транспортное Кольцо",
          icon: require("@i/icon-map-2.svg"),
          iconWidth: 32,
          iconHeight: 32,
          path: require("@i/path-map-6.svg"),
          isActive: false,
        },
        {
          title: "Садовое кольцо",
          icon: require("@i/icon-map-2.svg"),
          iconWidth: 32,
          iconHeight: 32,
          path: require("@i/path-map-7.svg"),
          isActive: false,
        },
        {
          title: "Проспект Мира",
          icon: require("@i/icon-map-2.svg"),
          iconWidth: 32,
          iconHeight: 32,
          path: require("@i/path-map-8.svg"),
          isActive: false,
        },
      ],
    };
  },
  methods: {
    itemControl(title) {
      this.dots.forEach((item) => {
        if (item.title == title) {
          item.isActive = !item.isActive;
        } else {
          item.isActive = false;
        }
      });
    },
  },
  mounted() {
    if (this.mq.mdMinus) {
      const block = this.$refs.map;
      const scrollLeft =
        this.$refs.marker.offsetLeft + 28 - window.innerWidth / 2;
      block.scrollLeft = scrollLeft;
      block.addEventListener("touchend", () => {
        if (block.scrollLeft != scrollLeft) {
          gsap.to(this.$refs.helper, { autoAlpha: 0, duration: 0.3 });
        }
      });
    }
  },
};
</script>

<style scoped>
.map {
  overflow-x: auto;
}
.wrap {
  width: 1440px;
  height: 700px;
  position: relative;
  margin: 0px auto;
}
.map:before,
.wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 30px;
  height: 100%;
  background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
  pointer-events: none;
}
.map:after,
.wrap:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 30px;
  height: 100%;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff);
  pointer-events: none;
}
.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.marker {
  position: absolute;
  top: 227px;
  left: 700px;
}
.dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.dot:nth-child(1) {
  top: 99px;
  left: 569px;
}
.dot:nth-child(2) {
  top: 77px;
  left: 708px;
}
.dot:nth-child(3) {
  top: 433px;
  left: 680px;
}
.dot:nth-child(4) {
  top: 167px;
  left: 636px;
}
.dot:nth-child(5) {
  top: 346px;
  left: 545px;
}
.dot:nth-child(6) {
  top: 557px;
  left: 876px;
}
.dot:nth-child(7) {
  top: 664px;
  left: 581px;
}
.dot:nth-child(8) {
  top: 254px;
  left: 1250px;
}

.paths {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.path {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
}
.path.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.path img {
  width: auto;
  height: auto;
  max-width: none;
}
.path:nth-child(1) {
  top: 73px;
  left: 595px;
  transform-origin: 95% 100%;
}
.path:nth-child(2) {
  top: 63px;
  left: 734px;
  transform-origin: 50% 99%;
}
.path:nth-child(3) {
  top: 284px;
  left: 702px;
  transform-origin: 87% 0%;
}
.path:nth-child(4) {
  top: 154px;
  left: 660px;
  transform-origin: 100% 100%;
}
.path:nth-child(5) {
  top: 261px;
  left: 566px;
  transform-origin: 100% 10%;
}
.path:nth-child(6) {
  top: 280px;
  left: 736px;
  transform-origin: 0% 0%;
}
.path:nth-child(7) {
  top: 276px;
  left: 570px;
  transform-origin: 100% 0%;
}
.path:nth-child(8) {
  top: 207px;
  left: 756px;
  transform-origin: 0% 80%;
}

.dot__icon {
  position: absolute;
  bottom: 3px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  border: 4px solid #fff;
  border-radius: 50%;
  background: linear-gradient(180deg, #509c3b 0%, #95c389 100%);
  cursor: pointer;
  user-select: none;
}

.dot__icon::after {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border: solid transparent;
  border-width: 5px;
  border-color: rgb(255 255 255 / 0);
  border-top-color: #fff;
  pointer-events: none;
}

body.-notouch .dot:hover .dot__icon {
  background: linear-gradient(180deg, #62a650 0%, #a0c994 100%);
}

body.-notouch .dot:active .dot__icon {
  background: linear-gradient(180deg, #498c36 0%, #86af7a 100%);
}

.dot__title {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 6px;
  transform: translateX(-50%);
  font-size: 14px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  white-space: nowrap;
  line-height: 1.142;
  text-align: center;
  padding: 5px 8px 7px 8px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s, visibility 0.1s;
}
body.-notouch .dot:hover .dot__title,
.dot.active .dot__title {
  opacity: 1;
  visibility: visible;
}

.dot.active .dot__icon {
  background: linear-gradient(180deg, #4a9037 0%, #509c3b 100%) !important;
}

.swiper-helper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 90px;
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 0) 0%,
    rgb(255 255 255 / 1) 100%
  );
  pointer-events: none;
}

.swiper-helper__inner {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 190px;
  height: 30px;
  margin-left: -95px;
}

.swiper-helper__inner::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border: 5px solid #509c3b;
  border-radius: 50%;
  animation: swipe 1.25s ease-out infinite;
}
@keyframes swipe {
  0% {
    transform: translateX(160px);
  }

  20% {
    transform: translateX(160px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
