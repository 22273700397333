export default function (form, title, message) {
  return new Promise((resolve, reject) => {
    let name = "";
    if (form.name !== undefined) {
      name += form.name;
    }
    if (form.lastName !== undefined) {
      name += " ";
      name += form.lastName;
    }
    if (form.secondName !== undefined) {
      name += " ";
      name += form.secondName;
    }
    window.Comagic.addOfflineRequest(
      {
        name:
          "Имя: " + name + " | " + "Форма: " + title.replace(/&nbsp;/g, " "),
        phone: form.phone,
        email: form.email,
        message: message.replace(/&nbsp;/g, " "),
      },
      (callback) => {
        const callbackResponse = JSON.parse(callback.response);
        if (callbackResponse.success === true) {
          resolve({
            code: 1,
            text:
              "<b>" +
              form.name +
              '</b>, благодарим за обращение. Мы свяжемся с вами в ближайшее время по телефону <b><span style="display: inline-block;">' +
              form.phone +
              "</span></b>",
          });
          console.log("Заявка успешно получена CoMagic");
        } else {
          reject(new Error("Ошибка обработки заявки на стороне CoMagic"));
          console.log("Ошибка обработки заявки на стороне CoMagic");
        }
      }
    );
  });
}
