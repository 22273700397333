<template>
  <section class="flat-slider home-block">
    <div v-if="particle" ref="particle" class="particle d-none d-md-block" />
    <div class="content">
      <div class="container">
        <div ref="title" class="title"><slot name="title" /></div>
        <SlidesScroll v-if="mq.lgPlus">
          <template v-for="item in items">
            <slot name="item" v-bind="item" />
          </template>
        </SlidesScroll>
        <div v-else class="list">
          <div class="flat-slider__mob-control">
            <button class="flat-slider__button-left flat-slider__button"><img src="@/assets/i/cursor-arrow.png"></button>
            <button class="flat-slider__button-right flat-slider__button"><img src="@/assets/i/cursor-arrow.png"></button>
          </div>
          <swiper
            class="hasPagination"
            :pagination="{ clickable: true }"
            :preload-images="false"
            :slides-per-view="'auto'"
            :space-between="24"
            :navigation="{
              nextEl: '.flat-slider__button-right',
              prevEl: '.flat-slider__button-left'
            }"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="(item, i) in items" :key="i" class="flat-slider__mob-item">
              <slot name="item" v-bind="item" />
            </swiper-slide>
          </swiper>
        </div>
        <div ref="bottom" class="bottom"><slot name="bottom" /></div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesScroll from "@/components/SlidesScroll.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    SlidesScroll,
    GalleryItem,
    Swiper,
    SwiperSlide,
  },
  inject: ["mq"],
  props: {
    items: {
      type: Array,
      required: true
    },
    particle: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "10% center",
            scrub: 1.5,
          },
        })
        .from(this.$refs.title, {
          y: 150,
        });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.bottom,
            toggleActions: "play reverse play reverse",
          },
        })
        .from(this.$refs.bottom, {
          y: 50,
          autoAlpha: 0,
        })
        .to(
          this.$refs.particle, {
            autoAlpha: 0
          },
          0
        );
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>

<style scoped>
.particle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1474px;
  height: 1217px;
  margin-left: -737px;
  background: url(~@i/bg-parks.svg) 0 0 no-repeat;
  background-size: contain;
}

.content {
  position: relative;
}

.title {
  margin-bottom: 80px;
  text-align: center;
}
.list {
  position: relative;
}
.list .swiper-container {
  overflow: inherit;
}

.list :deep(.swiper-slide) {
  width: 392px;
}

.bottom {
  margin-top: 80px;
}
.flat-slider__mob-control {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flat-slider__button {
  position: absolute;
  z-index: 2;
  width: 44px;
  height: 44px;
  border: 0;
  background: transparent;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}
.flat-slider__button-left {
  left: -9px;
}
.flat-slider__button-left img {
  transform: rotate(180deg);
}
.flat-slider__button-right {
  left: 291px;
}
@media (max-width: 1279px) {
  .title {
    margin-bottom: 65px;
  }
}
@media (max-width: 991px) {
  .title {
    margin-bottom: 40px;
  }

  .list :deep(.item__image) {
    height: 450px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 187px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 32px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 203px;
  }

  .bottom {
    margin-top: 110px;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }

  .list :deep(.item__text) {
    font-size: 14px;
    line-height: 1.4;
  }

  .list :deep(.item__image) {
    height: 400px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 178px;
  }
}
@media (max-width: 374px) {
  .list :deep(.swiper-slide) {
    width: 290px;
  }
}
</style>
