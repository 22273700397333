<template>
  <section class="home-block">
    <div class="background">
      <div ref="background" class="video-background">
        <picture>
          <source
            :srcset="require('@i/video/home-lifestyle-s.jpg')"
            media="(max-width: 767px)"
          />
          <img
            :src="require('@i/video/home-lifestyle.jpg')"
            loading="lazy"
            width="1920"
            height="1080"
            alt=""
          />
        </picture>
      </div>
    </div>
    <div class="content">
      <!-- <StoriesBlock /> -->
      <div class="container">
        <h1 ref="h1" class="h4">
          Эко-квартал VERY на&nbsp;Ботанической,&nbsp;29
        </h1>
        <div ref="title" class="title h1">
          <span class="text-masked">Перемены</span> восхищают
        </div>
        <div class="row">
          <div
            class="col-xl-5 col-lg-7 col-md-9 offset-xl-5 offset-lg-4 offset-md-3"
          >
            <div ref="text" class="text">
              <p>
                VERY полностью меняет восприятие жизни в&nbsp;Москве. Город
                здесь покоряется природе, становясь её необходимым
                и&nbsp;комфортным приложением. 77%&nbsp;площади квартала, это
                почти четыре футбольных поля&nbsp;&mdash; отдано
                благоустройству: прогулкам, играм, приватному отдыху. Уникальный
                микроклимат создают более тысячи специально высаженных деревьев
                и&nbsp;кустарников.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
// import StoriesBlock from "@/components/StoriesBlock.vue";
export default {
  // components: { StoriesBlock },
  mounted() {
    lazyVideo(this.$el);

    if (!is_touch()) {
      gsap.fromTo(
        this.$refs.background,
        { y: "-25%" },
        {
          y: "25%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: true,
          },
          ease: "none",
        }
      );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 60%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([
            this.$refs.h1,
            this.$refs.title,
            this.$refs.text,
          ]),
          {
            y: 50,
            stagger: { each: 0.1 },
          }
        );
    }
  },
};
</script>

<style scoped>
.home-block {
  padding-top: 0;
}

body.-notouch .video-background {
  top: -25%;
  bottom: -25%;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 0.5);
}

.content {
  position: relative;
}

h1 {
  text-align: center;
}

.title {
  margin: 0 0 50px;
  font-size: 160px;
  line-height: 0.875;
}

.text {
  font-size: 20px;
}

@media (max-width: 1279px) {
  .title {
    font-size: 136px;
  }
}
@media (max-width: 991px) {
  .title {
    font-size: 112px;
  }
}
@media (max-width: 767px) {
  .content {
    text-align: center;
  }

  .title {
    margin-bottom: 30px;
    font-size: 88px;
  }

  .text {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 64px;
  }
}
</style>
