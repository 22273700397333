<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="vfm-gallery"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div class="vfm-gallery__container">
        <div class="h3 title" v-html="title"></div>
        <div class="vfm-panorama">
          <iframe
            :src="src"
            width="100%"
            height="100%"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            frameborder="no"
          ></iframe>
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
  },
  methods: {
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped src="@css/popup.css"></style>
<style scoped>
.vfm-panorama {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0px -65px;
}
.vfm-panorama iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .title {
    display: none;
  }
  .vfm__inner {
    padding: 0px;
  }
  .vfm-panorama {
    margin: 0px;
    height: calc(98vh - 60px);
  }
}
</style>
