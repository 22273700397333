<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="vfm-gallery"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div class="vfm-gallery__container">
        <div class="h3 title" v-html="title" />
        <div class="list__wrap">
          <FloorPlan class="plan" @flatClick="close" :img="img" :points="points" />
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
import FloorPlan from "@/components/FloorPlan.vue";

export default {
  components: {
    FloorPlan,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    img: {
      require: false
    },
    points: {
      require: false
    },
  },
  methods: {
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped src="@css/popup.css"></style>
<style scoped>
.title {
  position: relative;
  z-index: 1;
  margin-right: 60px;
}

.plan >>> .tooltip__area polygon,
.plan >>> .tooltip__area polygon:hover {
  opacity: 1;
}

.plan >>> .tooltip__area polygon {
  fill: #20564033;
}

.plan >>> .tooltip__area polygon:hover {
  fill: #20564066;
}

.plan >>> .tooltip__area polygon.current {
  fill: #20564092;
  pointer-events: none;
}

.vfm__container {
  overflow-x: hidden;
}
</style>
