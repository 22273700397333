<template>
  <div class="item text-white">
    <div class="item__image">
      <img
        width="496"
        height="320"
        loading="lazy"
        src="https://api.gkosnova.tech/public/storage/media/2021/10/800/Be9XsP9IZpQMD7tOX54MuCRAKAJEXnIR3S790gph.jpg"
        alt="Онлайн трансляция"
      />
      <div class="item__play btn-play">
        <icon icon="play" />
      </div>
    </div>
    <div class="item__content d-flex justify-content-end flex-column">
      <div class="item__title h4">
        <a
          href="javascript:void(0)"
          class="stretched-link cursor-zoom"
          aria-label="Открыть видео"
          @click="$vfm.show({ component: 'PopupLive' })"
        >
          Онлайн трансляция
        </a>
      </div>
      <div class="item__time">Камера 1</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.item {
  position: relative;
  flex: 0 0 496px;
  max-width: 496px;
  margin-right: 24px;
  padding-bottom: 64.5%;
}

.swiper-slide .item {
  flex: 0 0 100%;
  max-width: 100%;
}

.item:last-child {
  margin-right: 0;
}

.item__image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(63 68 64 / 0) 49.93%, #3f4440 100%);
}

.item__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.item__title {
  margin: 0 0 11px;
}

.item__title a {
  text-decoration: none;
}

.item__time {
  opacity: 0.8;
}
@media (max-width: 1279px) {
  .item__title {
    max-width: 410px;
    font-size: 33px;
    line-height: 1.1;
  }
}
@media (max-width: 991px) {
  .item__title {
    font-size: 30px;
  }

  .item__content {
    padding: 25px;
  }

  .item__time {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .item__title {
    margin-bottom: 5px;
    font-size: 27px;
  }

  .item__content {
    padding: 20px;
  }

  .item__time {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .item__title {
    font-size: 24px;
  }

  .item__content {
    padding: 15px;
  }
}

.item__play {
  position: absolute;
  top: 50%;
  left: 50%;
}

body.-notouch .item:hover .item__play {
  background: linear-gradient(180deg, #62a650 0%, #a0c994 100%);
}

body.-notouch .item:active .item__play {
  background: linear-gradient(180deg, #498c36 0%, #86af7a 100%);
}

@media (max-width: 575px) {
  .item__play {
    margin-top: -70px;
  }
}
</style>
