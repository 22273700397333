<template>
  <section class="home-block pb-0 service-apart">
    <div class="content text-center">
      <div class="container">
        <h2 ref="title" class="title">Сервисное управление</h2>
        <div class="service-apart__image">
          <picture>
            <source
              srcset="@/assets/i/very-main-desk.jpg"
              media="(min-width: 376px)"
            />
            <source
              srcset="@/assets/i/very-main-mob.jpg"
              media="(min-width: 0)"
            />
            <img
              src="@/assets/i/very-main-mob.jpg"
            />
          </picture>
        </div>
        <div class="service-apart__buttons">
          <button class="btn btn-default"
            data-popup-with-chats
            data-popup-with-chats-title="Узнать подробнее"
            data-popup-with-chats-source-id="Узнать подробнее"
          >Узнать подробнее</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    download () {
      const path = '/Service_apartments.pdf'
      const name = 'UP!ART'
      var link = document.createElement("a");
      link.href = path;
      link.download = name;
      link.dispatchEvent(new MouseEvent("click"));
    }
  }
}
</script>

<style scoped>
.service-apart__buttons {
  margin-top: 32px;
}
.service-apart .btn-link {
  color: #3F4440;
}
.service-apart .btn-default {
  background-color: #509C3B!important;
  border-color: #509C3B!important;
}
@media screen and (min-width: 768px) {
  .service-apart__image {
    margin-top: 56px;
  }
  .service-apart__buttons {
    margin-top: 56px;
  }
}
</style>
