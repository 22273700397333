export default [
  {
    "id": 'addition-110_12', title: '4-комн. квартира', "uuid": "addition-110_12",
    "status": "available","number": null,"cost": null,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": '4',"area": 110.12,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/unic-lot/66-78.jpg'),"image_thumb": require('@/assets/i/unic-lot/66-78.jpg')},"render_image": null
    },
    "building": '1',"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": null,"height": 1314,"width": 2098,"image_thumb": null}
    },
    "properties": {
      props: [
        '1-комн. квартира 39,81 м² №78',
        '2-комн. квартира 70,31 м² №66',
      ],
      root: 'UnicLotFlat',
      preview: require('@/assets/i/unic-lot/66-78-preview.jpg')
    },
    "floor": {
      "number": 8,
      "plan_image": {
        "source": require('@/assets/i/unic-lot/66-78-plan.jpg'),
        "height": 1755,
        "width": 2481,
        "image_thumb": require('@/assets/i/unic-lot/66-78-plan.jpg'),
      }
    },
    "area_points": [""]
  },
  {
    "id": "addition-108_12",title: '4-комн. квартира',"uuid": "addition-108_12",
    "status": "available","number": null,"cost": null,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 108.12,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/unic-lot/77-78.jpg'),"image_thumb": require('@/assets/i/unic-lot/77-78.jpg')},"render_image": null
    },
    "building": '1',"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": null,"height": 1314,"width": 2098,"image_thumb": null}
    },
    "properties": {
      props: [
        '1-комн. квартира 39,81 м² №78',
        '2-комн. квартира 68,31 м² №77',
      ],
      root: 'UnicLotFlat',
      preview: require('@/assets/i/unic-lot/77-78-preview.jpg')
    },
    "floor": {
      "number": 8,
      "plan_image": {
        "source": require('@/assets/i/unic-lot/77-78-plan.jpg'),
        "height": 1755,
        "width": 2481,
        "image_thumb": require('@/assets/i/unic-lot/77-78-plan.jpg'),
      }
    },
    "area_points": [""]
  },
  {
    "id": "addition-182_57",title: '6-комн. квартира',"uuid": "addition-182_57",
    "status": "available","number": null,"cost": null,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 182.57,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/unic-lot/586-587.jpg'),"image_thumb": require('@/assets/i/unic-lot/586-587.jpg')},"render_image": null
    },
    "building": '2',"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": null,"height": 1314,"width": 2098,"image_thumb": null}
    },
    "properties": {
      props: [
        '3-комн. квартира 68,59 м² №586',
        '4-комн. квартира 113,98 м² №587',
      ],
      root: 'UnicLotFlat',
      preview: require('@/assets/i/unic-lot/586-587-preview.jpg')
    },
    "floor": {
      "number": 19,
      "plan_image": {
        "source": require('@/assets/i/unic-lot/586-587-plan.jpg'),
        "height": 1755,
        "width": 2481,
        "image_thumb": require('@/assets/i/unic-lot/586-587-plan.jpg'),
      }
    },
    "area_points": [""]
  },
  {
    "id": "addition-132_96",title: '5-комн. квартира',"uuid": "addition-132_96",
    "status": "available","number": null,"cost": null,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 132.96,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/unic-lot/630-640.jpg'),"image_thumb": require('@/assets/i/unic-lot/630-640.jpg')},"render_image": null
    },
    "building": '2',"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": null,"height": 1314,"width": 2098,"image_thumb": null}
    },
    "properties": {
      props: [
        '1-комн. квартира 39,71 м² №640',
        '3-комн. квартира 93,25 м² №630',
      ],
      root: 'UnicLotFlat',
      preview: require('@/assets/i/unic-lot/630-640-preview.jpg')
    },
    "floor": {
      "number": 23,
      "plan_image": {
        "source": require('@/assets/i/unic-lot/630-640-plan.jpg'),
        "height": 1755,
        "width": 2481,
        "image_thumb": require('@/assets/i/unic-lot/630-640-plan.jpg'),
      }
    },
    "area_points": [""]
  },
  {
    "id": "addition-119_97",title: '4-комн. квартира',"uuid": "addition-119_97",
    "status": "available","number": null,"cost": null,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 119.97,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/unic-lot/639-638.jpg'),"image_thumb": require('@/assets/i/unic-lot/639-638.jpg')},"render_image": null
    },
    "building": '2',"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": null,"height": 1314,"width": 2098,"image_thumb": null}
    },
    "properties": {
      props: [
        '2-комн. квартира 67,64 м² №639',
        '2-комн. квартира 52,33 м² №638',
      ],
      root: 'UnicLotFlat',
      preview: require('@/assets/i/unic-lot/639-638-preview.jpg')
    },
    "floor": {
      "number": 23,
      "plan_image": {
        "source": require('@/assets/i/unic-lot/639-638-plan.jpg'),
        "height": 1755,
        "width": 2481,
        "image_thumb": require('@/assets/i/unic-lot/639-638-plan.jpg'),
      }
    },
    "area_points": [""]
  },
]
