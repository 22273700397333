<template>
  <section class="home-block pb-0">
    <div class="background">
      <div class="video-background">
        <img
          :src="require('@i/video/home-flats.jpg')"
          loading="lazy"
          width="1920"
          height="1080"
          alt=""
        />
      </div>
    </div>
    <div class="content text-center">
      <div class="container">
        <h2 ref="title" class="title">Выберите свою квартиру</h2>
        <div ref="text" class="text">
          <p>
            В&nbsp;4&nbsp;жилых домах VERY представлено 1&nbsp;456&nbsp;квартир:
            от&nbsp;функциональных студий площадью
            21-40&nbsp;кв.&nbsp;м&nbsp;до&nbsp;роскошных 5-комнатных квартир
            площадью до&nbsp;139&nbsp;кв.&nbsp;м.<br />
            Видовые квартиры начинаются уже с&nbsp;6&nbsp;этажа.
          </p>
        </div>
        <div ref="btn" class="btns d-lg-none">
          <router-link
            :to="{ name: 'PlanSearch' }"
            class="btn btn-small btn-yellow"
          >
            <Icon icon="settings" />Подбор по параметрам
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  mounted() {
    lazyVideo(this.$el);
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([this.$refs.title, this.$refs.text]),
          {
            y: 150,
            stagger: { each: 0.1 },
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(180deg, #fff 0%, rgb(255 255 255 / 0) 100%);
}

.background .video-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 0.6);
}

.content {
  position: relative;
  padding-bottom: 80px;
}

.text {
  max-width: 760px;
  margin: 0 auto;
}

.btns {
  margin-top: 35px;
}
@media (max-width: 991px) {
  .content {
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .content {
    padding-bottom: 45px;
  }

  .btns {
    margin-top: 30px;
  }
}
</style>
