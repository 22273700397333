<template>
  <section class="home-block">
    <div class="content">
      <div class="container">
        <h2 ref="title" class="title">Преимущества квартала</h2>
        <div v-if="preparedItems.length" id="feature">
          <SlidesScroll v-if="mq.lgPlus" :refresh-priority="0">
            <SlidesItem v-for="item in preparedItems" :key="item.title" :item="item" />
          </SlidesScroll>
          <div v-else class="list">
            <swiper
              class="hasPagination"
              navigation
              :pagination="{ clickable: true }"
              :preload-images="false"
              :slides-per-view="'auto'"
              :space-between="24"
              @swiper="onSwiper"
            >
              <swiper-slide v-for="item in preparedItems" :key="item.title">
                <SlidesItem :item="item" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesScroll from "@/components/SlidesScroll.vue";
import SlidesItem from "@/components/SlidesItem.vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    SlidesScroll,
    SlidesItem,
    Swiper,
    SwiperSlide,
  },
  inject: ["mq", 'mainStore'],
  computed: {
    items() {
      return this.mainStore.state.data?.benefit_project.slides ?? [];
    },
    preparedItems() {
      return this.items.map((item) => {
        return {
          img: item.preview.source,
          title: item.name,
          text: item.description,
          link: item.url
        };
      });
    }
  },
  mounted: function () {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "10% center",
            scrub: 1.5,
          },
        })
        .from(
          this.$refs.title,
          {
            y: 150,
          },
          0
        );
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 80px;
  text-align: center;
}

.list .swiper-container {
  overflow: inherit;
}

@media (max-width: 1279px) {
  .title {
    margin-bottom: 65px;
  }
}
@media (max-width: 991px) {
  .title {
    margin-bottom: 40px;
  }

  .list :deep(.swiper-slide) {
    width: 450px;
  }
}
@media (max-width: 767px) {
  .list :deep(.swiper-slide) {
    width: 375px;
  }

  .title {
    margin-bottom: 32px;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }
}
@media (max-width: 374px) {
  .title {
    font-size: 50px;
  }

  .list :deep(.swiper-slide) {
    width: 290px;
  }
}
</style>
