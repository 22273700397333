<template>
  <section class="home-block py-0">
    <div class="top text-white d-none d-lg-block">
      <div class="container">
        <h2 ref="title">
          Выбор <br />
          корпуса
        </h2>
        <div ref="btn">
          <router-link
            :to="{ name: 'PlanSearch' }"
            class="btn btn-small btn-yellow"
          >
            <Icon icon="settings" />Подбор по параметрам
          </router-link>
        </div>
      </div>
    </div>
    <Buildings />
  </section>
</template>

<script>
import Buildings from "@/components/planArchive/Buildings.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    Buildings,
  },
  data() {
    return {};
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "280px center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([this.$refs.title, this.$refs.btn]),
          {
            y: 100,
            stagger: { each: 0.1 },
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.top {
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  pointer-events: none;
}

.top h2,
.top .h2 {
  margin: 0 0 30px;
  line-height: 0.875;
}

.top .btn {
  pointer-events: auto;
}
</style>
