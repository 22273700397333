<template>
  <div class="dot" :class="{ active: dot.isActive }">
    <div class="dot__icon" @click="itemControl">
      <img
        :src="dot.icon"
        width="20"
        height="20"
        loading="lazy"
        :alt="'Иконка ' + dot.title"
      />
    </div>
    <div class="dot__popup">
      <!-- <HomeYardItemGallery v-if="dot.isActive && dot.gallery" :gallery="dot.gallery" /> -->
      <div v-if="dot.img" class="dot__gallery">
        <Transition name="fade-img">
          <img
            v-if="dot.isActive"
            :src="dot.img"
            width="320"
            height="200"
            loading="lazy"
            :alt="dot.title"
          />
        </Transition>
      </div>
      <div class="dot__content">
        <div class="h4" v-html="dot.title" />
        <p v-if="dot.text" v-html="dot.text" />
        <div v-if="dot.more" class="dot__btn">
          <router-link :to="dot.more" class="btn btn-default btn-small">
            Подробнее
          </router-link>
        </div>
      </div>
      <div class="dot__close" @click="itemControl">
        <Icon icon="close" />
      </div>
    </div>
  </div>
</template>

<script>
//import HomeYardItemGallery from '@/components/home/YardItemGallery.vue';
export default {
  components: {
    //HomeYardItemGallery,
  },
  props: {
    dot: {
      type: Object,
      required: true,
    },
  },
  emits: ["itemControl"],
  methods: {
    itemControl() {
      this.$emit("itemControl", [this.dot.name, this.$el]);
    },
  },
};
</script>
<style scoped>
.dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s, transform 0.1s;
  transform: translateY(5px);
}

.dot.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dot.visible.active {
  z-index: 1;
}

.dot:nth-child(1) {
  top: 48%;
  left: 39%;
}

.dot:nth-child(2) {
  top: 55%;
  left: 39%;
}

.dot:nth-child(3) {
  top: 48%;
  left: 55%;
}

.dot:nth-child(4) {
  top: 72%;
  left: 34%;
}

.dot:nth-child(5) {
  top: 87%;
  left: 18%;
}

.dot:nth-child(6) {
  top: 81%;
  left: 41%;
}

.dot:nth-child(7) {
  top: 84%;
  left: 56%;
}

.dot:nth-child(8) {
  top: 60%;
  left: 32%;
}

.dot:nth-child(9) {
  top: 61%;
  left: 47%;
}

.dot:nth-child(10) {
  top: 61%;
  left: 25%;
}

.dot:nth-child(11) {
  top: 43%;
  left: 36%;
}

.dot:nth-child(12) {
  top: 75%;
  left: 58%;
}

.dot:nth-child(13) {
  top: 63%;
  left: 34%;
}

.dot:nth-child(14) {
  top: 70%;
  left: 50%;
}

.dot:nth-child(15) {
  top: 49%;
  left: 36%;
}

.dot__icon {
  position: absolute;
  bottom: 3px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  border: 4px solid #fff;
  border-radius: 50%;
  background: linear-gradient(180deg, #509c3b 0%, #95c389 100%);
  cursor: pointer;
  user-select: none;
}

.dot__icon::after {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border: solid transparent;
  border-width: 5px;
  border-color: rgb(255 255 255 / 0);
  border-top-color: #fff;
  pointer-events: none;
}

body.-notouch .dot:hover .dot__icon {
  background: linear-gradient(180deg, #62a650 0%, #a0c994 100%);
}

body.-notouch .dot:active .dot__icon {
  background: linear-gradient(180deg, #498c36 0%, #86af7a 100%);
}

.dot.active .dot__icon {
  width: 58px;
  height: 58px;
  margin-left: -29px;
  border-width: 5px;
  background: linear-gradient(180deg, #4a9037 0%, #509c3b 100%) !important;
}

.dot.active .dot__icon::after {
  bottom: -14px;
}

.dot__popup {
  position: absolute;
  bottom: 82px;
  left: 50%;
  width: 320px;
  margin-left: -160px;
  background: #fff;
  box-shadow: 20px 20px 40px rgb(0 0 0 / 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s, transform 0.1s;
  transform: translateY(10px);
}

.dot.active .dot__popup {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dot__popup::after {
  content: "";
  position: absolute;
  bottom: -23px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -12px;
  border: solid transparent;
  border-width: 12px;
  border-color: rgb(255 255 255 / 0);
  border-top-color: #fff;
  pointer-events: none;
}

.dot__gallery {
  position: relative;
  height: 200px;
}

.dot__gallery img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dot__content {
  padding: 23px 20px 25px;
  text-align: center;
}

.dot__content h4,
.dot__content .h4 {
  margin: 0 0 5px;
  font-size: 24px;
  line-height: 1.2;
}

.dot__content h4:last-child,
.dot__content .h4:last-child {
  margin-bottom: 0;
}

.dot__content p {
  margin: 0;
  font-size: 12px;
}

.dot__btn {
  margin-top: 15px;
}

.dot__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 0;
  cursor: pointer;
}

.dot__close {
  background-color: #205640;
  color: #fff;
}

body.-notouch .dot__close:hover {
  background: #366753;
}

body.-notouch .dot__close:active {
  background: #1d4d3a;
}
@media (max-width: 767px) {
  .dot__popup {
    width: 304px;
    margin-left: -152px;
  }

  .dot__gallery {
    height: 150px;
  }

  .dot__content {
    padding-top: 15px;
    padding-bottom: 17px;
  }
}
@media (max-width: 1177px) and (min-width: 768px) {
  .dot:nth-child(1) .dot__popup,
  .dot:nth-child(3) .dot__popup,
  .dot:nth-child(11) .dot__popup {
    top: 20px;
    bottom: auto;
  }

  .dot:nth-child(1) .dot__popup::after,
  .dot:nth-child(3) .dot__popup::after,
  .dot:nth-child(11) .dot__popup::after {
    top: auto;
    bottom: 100%;
    border-color: rgb(255 255 255 / 0);
    border-bottom-color: #fff;
  }
}

.fade-img-enter-active,
.fade-img-leave-active {
  transition: opacity 0.2s;
}

.fade-img-enter-from,
.fade-img-leave-to {
  opacity: 0;
}
</style>
