<template>
  <div class="item">
    <div class="item__content">
      <div class="h3 item__title" v-html="item.title" />
      <div v-if="item.time" class="item__subtitle" v-html="item.time" />
      <div v-if="from == 'Parks'" class="item__thumb">
        <img
          loading="lazy"
          width="44"
          height="44"
          :src="
            Array.isArray(item.img)
              ? item.img[0].src
              : item.img instanceof Object
              ? item.img.src
              : item.img
          "
          :alt="item.title"
        />
      </div>
    </div>
    <div v-if="item.img" class="item__image cursor-zoom">
      <GalleryItemImages v-if="Array.isArray(item.img)" :item="item" />
      <img
        v-else
        class="img-to-animate"
        loading="lazy"
        width="600"
        height="420"
        :src="item.img instanceof Object ? item.img.src : item.img"
        :alt="item.title"
        @click.prevent="popupShow(item)"
      />
    </div>
    <div v-if="item.text" class="item__text" v-html="item.text" />
  </div>
</template>

<script>
import GalleryItemImages from "@/components/GalleryItemImages.vue";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    GalleryItemImages,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    from: {
      type: String,
      required: false,
    },
    noPopupMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupShow(item) {
      if (!this.noPopupMobile || !is_touch()) {
        this.$vfm.show({
          component: "PopupGallery",
          bind: {
            title: item.title,
            img: item.img instanceof Object ? [item.img] : [{ src: item.img }],
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.item {
  flex: 0 0 600px;
  max-width: 600px;
}

.swiper-slide .item {
  flex: 0 0 100%;
  max-width: 100%;
}

.item + .item {
  margin-left: 24px;
}

.item__content {
  margin-bottom: 20px;
  padding-right: 30px;
}

.item__title {
  margin-bottom: 0;
}

.item__subtitle {
  margin-top: 8px;
  margin-bottom: 33px;
  font-size: 20px;
}

.item__image {
  position: relative;
  overflow: hidden;
  padding-bottom: 70%;
}
/* .item__image:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(63, 68, 64, 0.6) 0%, rgba(63, 68, 64, 0) 50%, rgba(63, 68, 64, 0.6) 100%);
	} */
.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__text {
  margin-top: 23px;
  padding-right: 30px;
}

.item__thumb {
  display: none;
}

@media (max-width: 1279px) {
  .item {
    flex: 0 0 550px;
    max-width: 550px;
  }
}
@media (max-width: 991px) {
  .item {
    flex: 0 0 500px;
    max-width: 500px;
  }

  .swiper-slide .item {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    max-width: 100%;
  }

  .swiper-slide .item__title {
    font-size: 36px;
  }

  .swiper-slide .item__image {
    order: -1;
    margin-bottom: 20px;
  }

  .swiper-slide .item__text {
    margin: 0;
    font-size: 16px;
  }

  .swiper-slide .item__content {
    margin-bottom: 10px;
  }

  .swiper-slide .item__subtitle {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .item {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 35px;
  }

  .swiper-slide .item {
    margin-bottom: 0;
  }

  .item + .item {
    margin-left: 0;
  }

  .item + .item:last-child {
    margin-bottom: 0;
  }

  .item__content {
    margin-bottom: 20px;
    padding-right: 0;
  }

  .item__text {
    margin-top: 20px;
    padding-right: 0;
  }

  .item__subtitle {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .swiper-slide .item__text {
    font-size: 14px;
    line-height: 1.4;
  }
}
</style>
