<template>
  <div class="countdown-group">
    <div
      v-if="timerInited"
      :key="today.valueOf()"
      class="countdown-group__container"
    >
      <Countdown
        title="дней"
        :from-count="fromDays"
        :to-count="toDays"
        @animationEnd="onDay"
      />
      <Countdown
        title="часов"
        :from-count="fromHours"
        :to-count="toHours"
        @animationEnd="onHour"
      />
      <Countdown
        title="минут"
        :from-count="fromMinutes"
        :to-count="toMinutes"
        @animationEnd="onMinute"
      />
    </div>
  </div>
</template>

<script>
import Countdown from "@/components/Countdown";

export default {
  components: { Countdown },
  props: {
    date: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      fromDays: 0,
      toDays: 0,
      fromHours: 24,
      toHours: 0,
      fromMinutes: 60,
      toMinutes: 0,
      today: new Date(),
      default: null,
      interval: null,
      dayOffset: 3,
      timerInited: false,
    };
  },
  mounted() {
    this.setFromData();
    this.interval = setInterval(() => {
      this.today = new Date();
      this.setFromData();
    }, 1000 * 60);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    setFromData() {
      this.toDays = (this.date - this.today) / 1000 / 60 / 60 / 24;
      this.toDays = Math.trunc(this.toDays);
      if (!this.timerInited) {
        this.fromDays = this.toDays;
      }
      if (this.toDays < 0) {
        this.toDays = 0;
      }
      this.toHours = this.date - this.today - this.toDays * 24 * 60 * 60 * 1000;
      if (this.toHours < 0) {
        this.toHours = 0;
      } else {
        this.toHours = Math.trunc(this.toHours / 1000 / 60 / 60);
      }
      if (!this.timerInited) {
        this.fromHours = this.toHours;
      }
      this.toMinutes =
        this.date -
        this.today -
        this.toDays * 24 * 60 * 60 * 1000 -
        this.toHours * 60 * 60 * 1000;
      if (this.toMinutes < 0) {
        this.toMinutes = 0;
      } else {
        this.toMinutes = this.toMinutes / 1000 / 60;
        if (this.toMinutes % 100 > 0) {
          this.toMinutes = Math.trunc(this.toMinutes) + 1;
        } else {
          this.toMinutes = Math.trunc(this.toMinutes);
        }
      }
      if (!this.timerInited) {
        this.fromMinutes = this.toMinutes;
      }
      this.timerInited = true;
    },
    onDay(e) {
      this.fromDays = e;
    },
    onHour(e) {
      this.fromHours = e;
    },
    onMinute(e) {
      this.fromMinutes = e;
    },
  },
};
</script>

<style scoped>
.countdown-group {
  width: 320px;
}

@media (min-width: 768px) {
  .countdown-group__title {
    text-align: right;
    margin-bottom: 8px;
  }
}

.countdown-group__container {
  display: flex;
  justify-content: space-between;
}
</style>
