<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="vfm-video"
    :lock-scroll="lockScroll"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="vfm__inner">
      <div class="vfm-video__container">
        <iframe
          width="560"
          height="315"
          :src="
            'https://www.youtube.com/embed/' +
            youtube +
            '?autoplay=1&controls=1&disablekb=1&fs=0&modestbranding=1&playlist=' +
            youtube +
            '&loop=1&rel=0'
          "
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowfullscreen
        />
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
import { is_touch } from "@/helpers.js";
export default {
  props: {
    youtube: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lockScroll: !is_touch(),
    };
  },
  methods: {
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped src="@css/popup.css"></style>
<style scoped>
.vfm__inner {
  padding: 0;
}
</style>
