<template>
  <section class="home-block pt-0">
    <div class="map">
      <div ref="title" class="title h1">
        <div class="container">
          Двор <span class="text-masked">уникальных</span> впечатлений
        </div>
      </div>
      <div ref="helper" class="swiper-helper d-lg-none">
        <div class="swiper-helper__inner" />
      </div>
      <div ref="map" class="map__background">
        <picture width="2161" height="1642">
          <source type="image/webp" srcset="@i/home-yard-map.webp" />
          <img
            loading="lazy"
            src="@i/home-yard-map.jpg"
            alt="Схема двора"
            width="2161"
            height="1642"
          />
        </picture>
        <div ref="dots" class="map__dots">
          <HomeYardItem
            v-for="dot in dots"
            :key="dot.title"
            :class="{ visible: dot.type.includes(type) || !type }"
            :dot="dot"
            @itemControl="itemControl"
          />
        </div>
      </div>
      <ul ref="nav" class="map__nav d-flex justify-content-center">
        <li v-for="item in types" :key="item.id">
          <button
            class="btn btn-default btn-faded"
            :class="{ active: item.id == type }"
            @click="typeControl(item.id)"
          >
            {{ item.text }}
          </button>
        </li>
      </ul>
    </div>
    <div ref="bottomYard" class="container" id="progulka">
      <!-- <div class="text">
        <p>
          77% территории двора VERY занимает собственный парк. Узнайте больше
          о&nbsp;возможностях для семейного отдыха, занятий спортом
          и&nbsp;встреч с&nbsp;друзьями.
        </p>
      </div>
       -->
      <div class="btns-list d-flex justify-content-center">
        <div class="btns-list__item">
          <button
            type="button"
            @click="
              $vfm.show({
                component: 'PopupPanorama',
                bind: {
                  title: 'Прогулка по&nbsp;эко-кварталу',
                  src: 'https://tour.virtualland.ru/svl/very/',
                },
              })
            "
            class="btn btn-yellow"
          >
            Прогулка по&nbsp;эко-кварталу
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HomeYardItem from "@/components/home/YardItem.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default {
  components: {
    HomeYardItem,
  },
  inject: ["mq"],
  data() {
    return {
      types: [
        {
          id: "sport",
          text: "Спорт",
        },
        {
          id: "kids",
          text: "Детям",
        },
        {
          id: "fun",
          text: "Развлечения",
        },
      ],
      type: "",
      dots: [
        {
          title: "Воркаут",
          name: "workout",
          text: "",
          icon: require("@i/icon-yard-1.svg"),
          img: require("@i/html/sport/sport-2-640.jpg"),
          isActive: false,
          type: ["sport"],
          more: "",
        },
        {
          title: "Крытая детская площадка",
          name: "playground1",
          text: "",
          icon: require("@i/icon-yard-2.svg"),
          img: require("@i/html/kids/kids-6-640.jpg"),
          isActive: false,
          type: ["kids"],
          more: "",
        },
        {
          title: "SPA-комплекс с&nbsp;бассейном",
          name: "spa",
          text: "",
          img: require("@i/html/home/feats-2-640.jpg"),
          icon: require("@i/icon-yard-3.svg"),
          isActive: false,
          type: ["sport"],
          more: "",
        },
        {
          title: "Детская площадка",
          name: "playground2",
          text: "",
          img: require("@i/html/kids/kids-home-640.jpg"),
          icon: require("@i/icon-yard-4.svg"),
          isActive: false,
          type: ["kids"],
          more: "",
        },
        {
          title: "Детский сад VERY KIDS",
          name: "playground3",
          text: "",
          img: require("@i/html/home/feats-4-640.jpg"),
          icon: require("@i/icon-yard-5.svg"),
          isActive: false,
          type: ["kids"],
          more: {
            name: "NewsInner",
            params: {
              slug: "1728-v-semeinom-eko-kvartale-very-otkroetsya-innovatsionnii-detskii-sad-very-kids-s-eksklyuzivnimi-obrazovatelnimi-programmami",
            },
          },
        },
        {
          title: "Площадка для баскетбола и&nbsp;мини-футбола",
          name: "footballField",
          text: "",
          img: require("@i/html/sport/sport-5-640.jpg"),
          icon: require("@i/icon-yard-6.svg"),
          isActive: false,
          type: ["sport"],
          more: "",
        },
        {
          title: "Беседка",
          name: "summerHouse",
          text: "",
          img: require("@i/html/entertainment/entertainment-2-640.jpg"),
          icon: require("@i/icon-yard-7.svg"),
          isActive: false,
          type: ["fun"],
          more: "",
        },
        {
          title: "Фудтрак",
          name: "foodtrack",
          text: "",
          img: require("@i/html/entertainment/entertainment-3-640.jpg"),
          icon: require("@i/icon-yard-8.svg"),
          isActive: false,
          type: ["fun"],
          more: "",
        },
        {
          title: "Фонтан",
          name: "fountan",
          text: "",
          img: require("@i/html/entertainment/entertainment-4-640.jpg"),
          icon: require("@i/icon-yard-9.svg"),
          isActive: false,
          type: ["fun"],
          more: "",
        },
        {
          title: "Мост-ручей",
          name: "bridge",
          text: "",
          img: require("@i/html/entertainment/entertainment-1-640.jpg"),
          icon: require("@i/icon-yard-10.svg"),
          isActive: false,
          type: ["fun"],
          more: "",
        },
        {
          title: "Волейбол",
          name: "voleyball",
          text: "",
          img: require("@i/html/sport/sport-1-640.jpg"),
          icon: require("@i/icon-yard-11.svg"),
          isActive: false,
          type: ["sport"],
          more: "",
        },
        {
          title: "Пергола с&nbsp;качелями",
          name: "pergola",
          text: "",
          img: require("@i/html/entertainment/entertainment-7-640.jpg"),
          icon: require("@i/icon-yard-12.svg"),
          isActive: false,
          type: ["fun"],
          more: "",
        },
        {
          title: "Игровая площадка",
          name: "playground4",
          text: "",
          img: require("@i/html/entertainment/entertainment-15.jpg"),
          icon: require("@i/icon-slide.svg"),
          isActive: false,
          type: ["kids"],
          more: "",
        },
        {
          title: "Игровая площадка",
          name: "playground5",
          text: "",
          img: require("@i/html/entertainment/entertainment-14.jpg"),
          icon: require("@i/icon-park.svg"),
          isActive: false,
          type: ["kids"],
          more: "",
        },
        {
          title: "Игровая площадка",
          name: "playground6",
          text: "",
          img: require("@i/html/entertainment/entertainment-13.jpg"),
          icon: require("@i/icon-sand.svg"),
          isActive: false,
          type: ["kids"],
          more: "",
        },
      ],
    };
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 80%",
            end: "250px center",
            scrub: 1.5,
          },
        })
        .from(
          this.$refs.title,
          {
            y: 150,
          },
          0
        )
        .from(
          this.$refs.map,
          {
            y: 150,
            ease: "power3.out",
            clearProps: "all",
          },
          0
        );

      gsap.from(this.$refs.dots.children, {
        y: -50,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: this.$refs.dots,
          start: "top 30%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
          onLeave: () => {
            this.type = null;
          },
          onLeaveBack: () => {
            this.type = null;
          },
        },
        stagger: { each: 0.1 },
        clearProps: "all",
      });

      gsap.from(this.$refs.nav.children, {
        y: 150,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: this.$refs.nav,
          toggleActions: "play reverse play reverse",
        },
        stagger: { each: 0.1 },
      });

      gsap.from(this.$refs.bottomYard.children, {
        y: -50,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: this.$refs.bottomYard,
          start: "top 90%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
        stagger: { each: 0.1 },
      });
    }
    if (this.mq.mdMinus) {
      const block = this.$refs.map;
      const scrollLeft = 400 - window.innerWidth / 2;
      block.scrollLeft = scrollLeft;
      block.addEventListener("touchend", () => {
        if (block.scrollLeft != scrollLeft) {
          gsap.to(this.$refs.helper, { autoAlpha: 0, duration: 0.3 });
        }
      });
    }
  },
  methods: {
    itemControl(dot) {
      this.$refs.helper.style.display = "none";
      this.dots.forEach((el) => {
        if (el.name == dot[0]) {
          el.isActive = !el.isActive;
          if (this.mq.smMinus) {
            gsap.to(this.$refs.map, {
              scrollTo: { x: dot[1].offsetLeft - window.innerWidth / 2 },
              duration: 0.2,
              ease: "power2.inOut",
            });
          }
        } else {
          el.isActive = false;
        }
      });
    },
    typeControl(id) {
      if (this.type == id) {
        this.type = null;
      } else {
        this.type = id;
      }
    },
  },
};
</script>

<style scoped>
.title {
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 1;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

.text {
  max-width: 800px;
  margin: 0 auto;
  font-size: 20px;
  text-align: center;
}

.map {
  position: relative;
  margin: 0 0 55px;
}

.map__background {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 75.98334%;
}

.map__background picture,
.map__background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: auto;
}

.map__dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map__dots::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 315px;
  background: linear-gradient(
    0deg,
    rgb(63 68 64 / 0.6) 0%,
    rgb(63 68 64 / 0) 100%
  );
}

.map__nav {
  position: absolute;
  right: 0;
  bottom: 28px;
  left: 0;
  margin: 0;
  list-style: none;
}

.map__nav li {
  margin: 0 8px;
  padding: 0;
}

.swiper-helper {
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 90px;
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 0) 0%,
    rgb(255 255 255 / 1) 50%,
    rgb(255 255 255 / 0.2) 100%
  );
  pointer-events: none;
}

.swiper-helper__inner {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 190px;
  height: 30px;
  margin-left: -95px;
}

.swiper-helper__inner::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border: 5px solid #509c3b;
  border-radius: 50%;
  animation: swipe 1.25s ease-out infinite;
}

@media (max-width: 991px) {
  .map__background {
    padding: 0;
  }

  .map__background img,
  .map__background picture {
    position: relative;
  }

  .map__background img,
  .map__background picture,
  .map__dots {
    width: 991px;
    height: 753px;
  }

  .map::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 30px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgb(255 255 255 / 1) 0%,
      rgb(255 255 255 / 0) 100%
    );
    pointer-events: none;
  }

  .map::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 30px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgb(255 255 255 / 0) 0%,
      rgb(255 255 255 / 1) 100%
    );
    pointer-events: none;
  }
}
@media (max-width: 1279px) {
  .title {
    top: 50px;
  }
}
@media (max-width: 991px) {
  .title {
    top: 40px;
  }
}
@media (max-width: 767px) {
  .text {
    font-size: 16px;
  }

  .map {
    margin-bottom: 25px;
  }

  .btns-list {
    margin-top: 25px;
  }
}
@media (max-width: 575px) {
  .title span {
    display: block;
  }
}
@media (max-width: 991px) {
  .map__nav li {
    margin: 0 4px;
  }

  .map__nav .btn {
    padding: 7px 15px 10px !important;
    font-size: 18px !important;
  }
}
@media (max-width: 767px) {
  .map__nav {
    bottom: 20px;
  }
}
@media (max-width: 374px) {
  .map__nav li {
    margin: 0 2px;
  }

  .map__nav .btn {
    padding-right: 13px !important;
    padding-left: 13px !important;
  }
}

@keyframes swipe {
  0% {
    transform: translateX(160px);
  }

  20% {
    transform: translateX(160px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
