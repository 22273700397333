<template>
  <vue-final-modal
    v-slot="{ close }"
    esc-to-close
    class="popup-promo"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="popup-promo__body">
      <div class="popup-promo__background">
        <img src="@i/popup-promo.jpg" alt="" />
      </div>
      <div class="popup-promo__content">
        <div class="popup-promo__title h4">
          Семейная ипотека <br />от Альфа-банка <br />
          5,6% ПВ 15%
        </div>
        <div class="popup-promo__button">
          <button
            class="btn btn-default btn-small"
            data-popup-with-chats
            data-popup-with-chats-title="Узнать подробнее"
            data-popup-with-chats-source-id="promo-popup"
            @click="close"
          >
            Узнать подробнее
          </button>
        </div>
      </div>
      <a href="javascript:void(0)" class="vfm__close" @click="close"
        ><Icon icon="close"
      /></a>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  methods: {
    beforeOpen() {
      document.querySelector(".header").classList.add("overflow");
    },
    closed() {
      document.querySelector(".header").classList.remove("overflow");
    },
  },
};
</script>

<style scoped>
.popup-promo__body {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 584px;
  max-width: calc(100vw - 30px);
  margin: 0 15px;
  position: relative;
}

.popup-promo ::v-deep .vfm__content::before,
.popup-promo ::v-deep .vfm__content::after {
  display: none;
}

.popup-promo__content {
  flex: 1;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 30px 20px;
}

.popup-promo__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup-promo__title {
  margin-bottom: 16px;
  line-height: 40px;
}

.popup-promo ::v-deep span {
  display: inline-block;
}

.popup-promo__button {
  margin-top: auto;
  width: 390px;
  max-width: 100%;
}

.popup-promo__button .btn {
  width: 100%;
}

.popup-promo__background--no-mob {
  display: none;
}

.vfm__close {
  color: #fff;
}

@media screen and (max-width: 500px) {
  .popup-promo__title {
    font-size: 30px;
    line-height: 1.1;
  }
}

@media screen and (min-width: 768px) {
  .popup-promo__content {
    padding: 40px 20px;
  }
}

@media screen and (min-width: 991px) {
  .popup-promo__body {
    width: 985px;
    min-height: 312px;
  }

  .popup-promo__content {
    padding: 40px 20px;
    width: 430px;
  }

  .popup-promo__background--no-mob {
    display: block;
  }

  .popup-promo__background {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 430px);
    height: 100%;
  }
}
</style>
