<template>
  <section class="home-block pb-0">
    <div ref="content" class="content">
      <div class="container">
        <div ref="title" class="title h1">
          В ритме <span class="text-masked">города</span>
        </div>
        <!-- <div
          ref="controls"
          class="controls d-flex align-items-center justify-content-center"
        >
          <div class="controls__label -pano" @click="activeTab = 'Pano'">
            Панорама
          </div>
          <div
            class="controls__control"
            :class="'-' + activeTab.toLowerCase()"
            @click="activeTab = activeTab == 'Pano' ? 'Map' : 'Pano'"
          />
          <div class="controls__label -map" @click="activeTab = 'Map'">
            Карта
          </div>
        </div> -->
      </div>
    </div>

    <div id="map">
      <div ref="tabs" class="tabs-content">
        <transition name="fade">
          <keep-alive>
            <component
              :is="currentTabComponent"
              :class="'tabs-content__' + activeTab.toLowerCase()"
            />
          </keep-alive>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import RoadPano from "@/components/home/RoadPano.vue";
import RoadMap from "@/components/home/RoadMap.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    RoadPano,
    RoadMap,
  },
  data() {
    return {
      activeTab: "Map",
    };
  },
  computed: {
    currentTabComponent() {
      return "Road" + this.activeTab;
    },
  },
  mounted() {
    if (!is_touch()) {
      gsap.from(
        gsap.utils.toArray([
          this.$refs.title,
          this.$refs.controls,
          this.$refs.tabs,
        ]),
        {
          y: 150,
          stagger: { each: 0.1 },
          scrollTrigger: {
            trigger: this.$refs.content,
            start: "top bottom",
            end: "70% center",
            scrub: 1.5,
          },
        }
      );
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.content {
  position: relative;
}

.title {
  margin-bottom: 57px;
  text-align: center;
}

.controls {
  margin-bottom: 80px;
}

.controls__label {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  transition: color 0.1s;
}

body.-notouch .controls__label:hover {
  color: #205640;
}

.controls__control {
  position: relative;
  width: 80px;
  height: 44px;
  margin: 0 17px;
  border: 1px solid #e5e5e5;
  border-radius: 22px;
  cursor: pointer;
}

.controls__control::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #205640;
  background-image: url(~@i/text-mask.png);
  background-position: 50% 50%;
  background-size: 420px 560px;
  transition: transform 0.2s;
}

.controls__control.-map::before {
  transform: translateX(36px) rotate(90deg);
}

.tabs-content {
  position: relative;
}

.tabs-content__pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1279px) {
}
@media (max-width: 991px) {
  .title {
    margin-bottom: 37px;
  }

  .controls {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 27px;
  }

  .controls {
    margin-bottom: 40px;
  }
}
@media (max-width: 575px) {
  .controls {
    margin-bottom: 35px;
  }
}
</style>
