<template>
  <div class="pano">
    <iframe
      src="https://tour.virtualland.ru/very/"
      loading="lazy"
      width="100%"
      height="760"
      frameborder="0"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
