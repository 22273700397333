<template>
  <section class="home-block">
    <div ref="particle" class="particle d-none d-md-block" />
    <div class="content">
      <div class="container">
        <SlidesScroll>
          <GalleryItem
            v-for="item in items"
            :key="item.title"
            :item="item"
            :from="'Parks'"
            :class="{ mobileActive: item.mobileActive }"
          />
        </SlidesScroll>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesScroll from "@/components/SlidesScroll.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin);
export default {
  components: {
    SlidesScroll,
    GalleryItem,
  },
  data() {
    return {
      items: [
        {
          img: [
            { src: require("@i/html/home/parks-1-1.jpg") },
            { src: require("@i/html/home/parks-1-2.jpg") },
            { src: require("@i/html/home/parks-1-3.jpg") },
            { src: require("@i/html/home/parks-1-4.jpg") },
            { src: require("@i/html/home/parks-1-5.jpg") },
          ],
          title: "Ботанический сад",
          time: "5&nbsp;минут пешком",
          text: "Ботанический сад&nbsp;&mdash; это огромная озелененная парковая территория площадью более 360&nbsp;га., со&nbsp;своим разнообразным растительным миром, прогулочными тропинками и&nbsp;велодорожками.",
          mobileActive: this.$route.hash,
        },
        {
          img: [
            { src: require("@i/html/home/parks-2-1.jpg") },
            { src: require("@i/html/home/parks-2-2.jpg") },
            //{ src: require('@i/html/home/parks-2-3.jpg') },
            { src: require("@i/html/home/parks-2-4.jpg") },
            { src: require("@i/html/home/parks-2-5.jpg") },
          ],
          title: "Парк Останкино",
          time: "15&nbsp;минут пешком",
          text: "Один из&nbsp;старейших московских парков. На&nbsp;территории размером 71&nbsp;га. есть все для комфортного времяпровождения: велодорожки, воркаут-зоны, лодочные станции, танцплощадки, самый крупный скейт-парк в&nbsp;Европе и&nbsp;даже конный клуб.",
          mobileActive: this.$route.hash,
        },
        {
          img: [
            { src: require("@i/html/home/parks-3-1.jpg") },
            { src: require("@i/html/home/parks-3-2.jpg") },
            { src: require("@i/html/home/parks-3-3.jpg") },
            { src: require("@i/html/home/parks-3-4.jpg") },
            { src: require("@i/html/home/parks-3-5.jpg") },
          ],
          title: "ВДНХ",
          time: "25&nbsp;минут пешком",
          text: "Главное преимущество ВДНХ&nbsp;&mdash; расположение в&nbsp;центре города, высокая транспортная доступность и&nbsp;огромная территория исторического парка с&nbsp;четко продуманной инфраструктурой.",
          mobileActive: this.$route.hash,
        },
      ],
    };
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top top",
            end: "bottom center",
            scrub: true,
            ease: "none",
          },
        })
        .to(
          this.$refs.particle,
          {
            autoAlpha: 0,
          },
          0
        );
    }
    ScrollTrigger.matchMedia({
      "(max-width: 767px)": () => {
        ScrollTrigger.normalizeScroll(true);

        const items = gsap.utils.toArray(this.$el.querySelectorAll(".item"));
        items.forEach((item, index) => {
          ScrollTrigger.create({
            trigger: item,
            start: "top 65%",
            end: "bottom top",
            invalidateOnRefresh: true,
            onEnter: (self) => {
              this.items[index].mobileActive = true;
              gsap.fromTo(
                item.querySelector(".item__image"),
                { autoAlpha: 0 },
                { autoAlpha: 1 }
              );
              this.$nextTick(() => {
                ScrollTrigger.refresh();
              });
            },
            onLeaveBack: (self) => {
              this.items[index].mobileActive = false;
              gsap.fromTo(
                item.querySelector(".item__image"),
                { autoAlpha: 1 },
                { autoAlpha: 0 }
              );
              this.$nextTick(() => {
                ScrollTrigger.refresh();
              });
            },
          });
        });

        // gsap.to(wrap, {
        // 	x: () => -(wrap.scrollWidth - pin.clientWidth),
        // 	ease: 'none',
        // 	snap: 'x',
        // 	scrollTrigger: {
        // 		trigger: pin,
        // 		start: 'center center',
        // 		end: () => '+=' + wrap.scrollWidth / 2,
        // 		pin: pin,
        // 		invalidateOnRefresh: true,
        // 		scrub: 0.5,
        // 		//markers: true,
        // 		//fastScrollEnd: fastScrollEnd,
        // 		//preventOverlaps: preventOverlaps,
        // 		// onUpdate: (e) => {
        // 		// 	console.log(e.progress);
        // 		// },
        // 	},
        // });
      },
    });
  },
  // methods: {
  //   itemControl(item) {
  // 		this.items.forEach((el) => {
  // 			if (el.title == item[0]) {
  // 				el.mobileActive = true;
  //         gsap.to(window, { scrollTo: {y: item[1], offsetY: 90}, duration: 0.5, ease: 'power2.inOut' });
  // 			} else {
  // 				el.mobileActive = false;
  // 			}
  // 		});
  //   }
  // }
};
</script>

<style scoped>
.particle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1474px;
  height: 1217px;
  margin-left: -737px;
  background: url(~@i/bg-parks.svg) 0 0 no-repeat;
  background-size: contain;
}

.content {
  position: relative;
}

@media (max-width: 767px) {
  .item {
    margin: 0 0 15px;
    padding: 0 0 15px;
    border-bottom: 1px solid #e5e5e5;
  }

  .item:last-child {
    margin: 0;
    border-bottom: 0;
  }

  .item :deep(.item__subtitle) {
    margin-top: 5px;
    margin-bottom: -4px;
    font-size: 14px;
    transition: all 0.15s;
  }

  .item :deep(.item__title) {
    transition: all 0.15s;
  }

  .item:not(.mobileActive) :deep(.item__content) {
    position: relative;
    margin: 0;
  }

  .item:not(.mobileActive) :deep(.item__image),
  .item:not(.mobileActive) :deep(.item__text) {
    overflow: hidden;
    height: 0;
    margin: 0;
    padding: 0;
  }

  .item:not(.mobileActive) :deep(.item__title) {
    font-size: 24px;
  }

  .item:not(.mobileActive) :deep(.item__subtitle) {
    margin-top: 2px;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 14px;
  }

  .item:not(.mobileActive) :deep(.item__thumb) {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 44px;
    height: 44px;
  }

  .item:not(.mobileActive) :deep(.item__thumb img) {
    position: absolute;
    top: 1px;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
@media (max-width: 575px) {
  .item :deep(.item__text) {
    margin-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
  }

  .item:last-child :deep(.item__text) {
    padding-bottom: 0;
  }
}
</style>
